.thumb-grid{    
    .thumb-block{
        position:relative; 
        overflow: hidden;
        padding: 6*$pad 5*$pad;
        margin: 0 -3*$pad;

        .list-grid-4{
            li{      
                padding:$pad;          
                -ms-flex: 0 0 50%;
                flex: 0 0 50%;
                max-width: 50%;
                    
                &.two-column{
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }

    .inner {
        h2{            
            padding-top:4*$pad;
            padding-bottom:2*$pad;
        }  
    }

    @media (min-width:700px){
        .thumb-block{
            .list-grid-4{
                li{
                    -ms-flex: 0 0 25%;
                    flex: 0 0 25%;
                    max-width: 25%;

                    &.two-column{
                        -ms-flex: 0 0 50%;
                        flex: 0 0 50%;
                        max-width: 50%;    
                        
                        figure {
                            padding-top: 50%;
                        }
                    }
                }        
            }
        }
    }
     
    @media (min-width:992px){
        .inner{
            padding:0 15*$pad; 
        }
        .thumb-block{
            padding: 10*$pad;            
        }
    }       
}