.filter-calendar {
	
	.acc-info{ 
		position: absolute;
		width: 100%;
		left: 0px;
		z-index: 9;	
	}
	.calendar-block{ 
		.pignose-calendar {
			width: 100%;
			max-width: 100%;
			background-color: #fff;
			border: 2px solid $dblue;
			padding:4*$pad 8*$pad;
			@include box-shadow(initial);

			.pignose-calendar-top{
				padding: .8em 0;
				background-color:$trans;
				border-bottom: 2px solid #d1d1d3;
				@include box-shadow(initial);
				
				.pignose-calendar-top-nav{
					&.pignose-calendar-top-prev {
						margin-left: -9px;
					}
					&.pignose-calendar-top-next {
						margin-right: -9px;
					}
				}

				.pignose-calendar-top-date {					
					padding: 0.8em 0;	
					text-transform: inherit;

					.pignose-calendar-top-month {
						display: inline-block;						
						margin-bottom: 0;
						font-size: 100%;					
					}
					.pignose-calendar-top-year {
						display: inline-block;
						font-size: 100%;					
					}				
				}
				
			}

			.pignose-calendar-header {
				padding: 0;	
				margin-top: 3*$mar;			
			}
			.pignose-calendar-body {
				padding: 0;
			}
			.pignose-calendar-unit {				
				height: 40px;
				line-height: 40px;

				a {					
					width: 40px;
					height: 40px;
					border-radius: 0;
					color: $dgrey;
					line-height: 40px;
				}
				&.pignose-calendar-unit-active{
					a {
						background-color: $dblue;
						color: #fff;
						font-weight: $regular;
						@include box-shadow(initial);
					}			
				}			
			}
		}
	}
}  

@media (min-width:992px){
	.filter-calendar {
		.acc-info{ 
			width: 360px;
		}
		.calendar-block{ 
			.pignose-calendar {				
				.pignose-calendar-unit {					
					width: 39px;								
				}
			}
		}
	}
}

