.two-column-txt {
	position: relative;	

	.column-6 {
		opacity: 0;
	}

	@media (min-width:992px){		
		h2{
			@include font-size(44px, 16px); 
		}		
	}
}

