$unit:100%/12;

[class*=col-],
%col{
	@extend %dfp;
	margin-right:$gutter;
	margin-bottom:$gutter;
	&.clear{
		clear:left;
	}
	@include media($bp-xs){
		margin-right:20px;
			margin-bottom:20px;
	}
}

.col-row{
	width: calc(100% + #{$gutter});
	outline:0;
	margin-bottom:0;
	margin-bottom:-$gutter;
	@include media($bp-xs){
		width: calc(100% + 20px);
		margin-bottom:-20px;
	}
}

ul.col-row{
	padding:0;
	margin-top:0;
	&,
	>li{
		list-style:none;
	}
}

@for $i from 1 through 12{
	.col-#{$i}{
		width:calc((#{$unit} * #{$i}) - #{$gutter});
	}
}

@include media($bp-m){
	@for $i from 1 through 12{
		.col-m-#{$i}{
			width:calc((#{$unit} * #{$i}) - #{$gutter});
		}
		.clear-m{
			clear:left;
		}
	}
}

@include media($bp-s){
	@for $i from 1 through 12{
		.col-s-#{$i}{
			width:calc((#{$unit} * #{$i}) - #{$gutter});
		}
		.clear-s{
			clear:left;
		}
	}
}

@include media($bp-xs){
	@for $i from 1 through 12{
		.col-#{$i},
		.col-m-#{$i},
		.col-s-#{$i}{
			width:calc((#{$unit} * #{$i}) - 20px);
		}
		.col-xs-#{$i}{
			width:calc((#{$unit} * #{$i}) - 20px);
		}
		.clear-xs{
			clear:left;
		}
	}
}

.row-col {
	display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	margin-left:-15px;
	margin-right:-15px;
	@include selfClearing;

	[class*=column-]{
		@include flex-width(100%);
		margin:0px;
		padding-left:15px;
		padding-right:15px; 
	}	
	
	@media (min-width:992px){
		.column-1 {@include flex-width(8.333333%);}
		.column-2 {@include flex-width(16.666667%);}	
		.column-3 {@include flex-width(25%);}	
		.column-4 {@include flex-width(33.333333%);}		
		.column-5 {@include flex-width(41.666667%);}
		.column-6 {@include flex-width(50%);}
		.column-7 {@include flex-width(58.333333%);}
		.column-8 {@include flex-width(66.666667%);}
		.column-9 {@include flex-width(75%);}
		.column-10 {@include flex-width(83.333333%);}
		.column-11 {@include flex-width(91.666667%);}
		.column-12 {@include flex-width(100%);}
	} 
}


