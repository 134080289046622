.noticeboard-grid{    
    .inner{
        .row-col{                            
            position:relative;
            overflow: hidden;

            .filter-controls{
                main{
                    .filter-grid{
                        border-bottom:2px solid $grey;
                        margin-bottom:4*$mar;
                        padding-bottom:3*$pad;                    
                    }
                }
            }

            .filter-result-show{
                margin-bottom:6*$mar;

                .boards{
                    margin-bottom:6*$mar;                
                }
            }

            .s-post-today{
                background-color:$lgrey;
                padding:4*$pad 5*$pad 5*$pad;
                margin-bottom:4*$mar;
                
                h3{
                    @include font-size(30px, 16px);
                    font-weight: $med;            
                }
                p{
                    margin:0 0 5*$mar;          
                }
                .dblue-cta{
                    padding:3*$pad 5*$pad;        
                }
            }

            .type-of-content{
                padding:4*$pad 5*$pad;
                
                .whatson-item{background: $red;}
                .explore-item{background: $yellow;}
                .experience-item{background: $blue;}
                .shop-item{background: $dblue;}
                .taste-item{background: $green;}
                .sleep-item{background: $purple;}
                .live-item{background: $lblue;}

                [class*=implicit-grid]{                    
                    grid-gap:4*$pad 4*$pad;                
                    li{
                        text-align: center;
                        color:$white;
                        a{
                            text-align: center;
                            color:$white;
                            padding:3*$pad;
                            display: block;
                            width: 100%;

                            &:hover{                                
                                color:rgba($white, 0.6);                               
                            }
                        }
                    }
                }
            }
        }
    }

    @media(min-width:992px){
        .inner{
            .row-col{
                &:before {
                    content: "";
                    position: absolute;
                    left: 66.66667%;
                    z-index: 1; 
                    width: 2px; 
                    height: 100%;
                    background: $grey;
                }

                .column-8{
                    padding-right:9*$pad;                
                }
                .column-4{
                    padding-left:9*$pad;                
                }

                .filter-controls{
                    main{
                        padding-bottom:0;
                    }
                }

                .filter-result-show{
                    margin-bottom:0;                   
                }
            } 
        }
    }
}








