form {
	&.form-detail {
		.form-group {
			margin-bottom: 3 * $mar;

			label {
				display: inline-block;
				margin-bottom: $mar;
				@include font-size(14px, 16px);
				font-weight: $bold;
				font-family: $aktivGrotesk;
			}
			input {
				max-width: 100%;
			}

			.form-control {
				display: block;
				width: 100%;
				height: calc(2em + 0.75rem + 2px);
				padding: 0.375rem 0.75rem;
				background-color: $lgrey;
				background-clip: padding-box;
				border: 2px solid $black;
				transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
			}

			textarea {
				resize: vertical;
				min-height: 175px;
				max-height: 300px;
			}

			select {
				font-family: $aktivGrotesk;
			}
		}
	}
}

//Create post page form
.postForm {
	#confirm-checkbox {
		padding: 1rem 0;
	}
}

input[type="checkbox"],
input.checkbox {
	position: relative;
	display: inline-block !important;
	appearance: none;
	vertical-align: top;
	width: 18px !important;
	height: 18px !important;
	background: $white;
	border: solid 2px $dblue !important;
	@include transition(all, 0.2s);
	cursor: pointer;

	&:checked {
		background: $dblue;
	}

	&.input-validation-error {
		background: $white !important;
	}

	& + label {
		display: inline-block;
		width: calc(100% - 25px - 0.5rem);
		padding-left: 0.5rem;
		font-size: 0.8rem;
		font-family: $openSans;
	}
}

.select-wrapper {
	position: relative;

	select {
		appearance: none !important;
		cursor: pointer;
		margin: 0 !important;
		border: none !important;
		width: 100% !important;
	}

	&::before {
		content: "\f107";
		@extend .fas;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12.5px;
		color: $white;
		z-index: 1;
		width: 25px;
		font-size: 1.2rem;
		font-weight: 400;
		text-align: center;
		pointer-events: none;
		cursor: pointer;
	}

	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
		height: 100%;
		background: $dblue;
		pointer-events: none;
		cursor: pointer;
	}
}

.tags-input-wrapper {
	position: relative;

	.tags-input-labels {
		display: flex !important;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		left: 0.75rem;
		padding: 0.375rem 0.75rem;
		max-width: 100%;
		width: 100%;
		height: auto !important;
		min-height: calc(2em + 0.75rem + 2px);
		background-color: $lgrey !important;
		border: 2px solid $black !important;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s !important;

		input {
			width: auto !important;
			border: none;
			height: 100%;
			background: transparent;
			padding: 0;

			&:focus {
				box-shadow: none !important;
				outline: none !important;
			}
		}

		span {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			background: #fff;
			font-family: $aktivGrotesk;
			border: solid 2px $dblue;
			padding: 0.35rem 1rem 0.35rem 0.5rem;
			font-size: 0.875rem;
			font-weight: $med;
			margin-right: 0.5rem;
			margin-bottom: 0.25rem;
			margin-top: 0.25rem;

			i {
				position: absolute;
				right: -0.5rem;
				top: 50%;
				transform: translateY(-50%);
				width: 10px;
				cursor: pointer;

				position: relative;
				&::before {
					@extend .fas;
					content: "\f00d";
					font-weight: 400;
					font-size: 0.7rem;
					color: $dblue;
				}
			}
		}
	}

	input[type="checkbox"],
	br {
		display: none !important;
	}
}

.tags-input-hidden {
	position: absolute;
	height: 0;
	width: 0;
	overflow: hidden;
	visibility: hidden;
}

.tags-input-suggested {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;

	> p {
		flex: 0 0 100%;
		padding: 0.5rem 0 0 0;
		font-family: $aktivGrotesk;
		font-weight: $med;
	}

	> span {
		flex: calc(50% - 0.5rem);
		position: relative;
		display: inline-block;
		vertical-align: middle;
		background: #fff;
		font-family: $aktivGrotesk;
		border: solid 2px $dblue;
		padding: 0.35rem 0.5rem 0.35rem 0.5rem;
		font-size: 0.875rem;
		font-weight: $med;
		margin: 0.25rem 0.25rem;
		cursor: pointer;
		text-align: center;
		@include transition(all, 0.2s);

		&:hover {
			background: $dblue;
			color: $white;
		}

		@include media(648px, min) {
			flex: 0 0 calc(33.33% - 1rem);
			margin: 0.25rem 0.5rem;
		}

		@include media(1024px, min) {
			flex: 0 0 calc(25% - 0.5rem);
			margin: 0.25rem 0.25rem;
		}
	}
}

.umbraco-forms-page {
	fieldset {
		padding: 0 !important;
		border: none !important;
	}

	.umbraco-forms-container {
		margin-right: 0 !important;
		width: 100% !important;
		margin-bottom: 0 !important;
	}

	.umbraco-forms-field {
		margin-bottom: 15px;

		.multiple-select {
			width: 100%;
			max-width: unset !important;

			.vscomp-toggle-button {
				display: flex;
				width: 100%;
				height: calc(2em + 0.75rem + 2px);
				padding: 0.375rem 0.75rem;
				background-color: $lgrey;
				background-clip: padding-box;
				border: 2px solid $black;
				transition: border-color 0.15s ease-in-out, box-shadow 0.15s;
			}

			.vscomp-search-input {
				border: none !important;
				height: auto !important;
				background: transparent !important;
			}
		}

		&.checkbox {
			display: flex;
			flex-direction: row-reverse;
			justify-content: flex-end;
			position: relative;

			label,
			span.umbraco-forms-tooltip {
				display: inline-block;
				width: calc(100% - 25px - 0.5rem);
				padding-left: 0.75rem;
				font-size: 0.8rem;
				font-family: $openSans;
				font-weight: 500;
			}

			input[type="checkbox"] {
				position: relative;
				display: inline-block !important;
				appearance: none;
				vertical-align: top;
				width: 18px !important;
				height: 18px !important;
				background: $white;
				border: solid 2px $dblue !important;
				@include transition(all, 0.2s);
				cursor: pointer;

				&:checked {
					background: $dblue;
				}
			}			
		}
	}

	.umbraco-forms-navigation {
		input[type="submit"] {
			@extend .cta;
		}
	}

	.dataConsent .umbraco-forms-field-wrapper {

		input[type=checkbox] {
			top: 3px;
		}

		label {
			display: inline-block;
			width: calc(100% - 25px - 0.5rem);
			padding-left: 0.75rem;
			font-size: 0.8rem;
			font-family: $openSans;
			font-weight: 500;
		}
	}

	label {
		display: block;
		margin-bottom: $mar;
		@include font-size(14px, 16px);
		font-weight: $bold;
		font-family: $aktivGrotesk;
	}
	input[type="text"],
	input[type="file"],
	textarea,
	select {
		max-width: 100% !important;
		display: block !important;
		width: 100% !important;
		height: calc(2em + 0.75rem + 2px) !important;
		padding: 0.375rem 0.75rem !important;
		background-color: $lgrey !important;
		background-clip: padding-box !important;
		border: 2px solid $black !important;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s !important;
	}

	textarea {
		resize: vertical;
		min-height: 175px;
		max-height: 300px;
	}

	select {
		font-family: $aktivGrotesk;
	}
}

.ui-autocomplete {
	background: #fff !important;
	list-style: none !important;
	padding: 15px;
	font-family: $aktivGrotesk;
	box-shadow: 2px 2px 10px rgba(#000, 0.5);
}

//Membership form
.titleanddescription {
	.umbraco-forms-field-wrapper {
		p {
			font-family: $aktivGrotesk;
			font-size: 0.875rem;
		}
	}
}

.privacypolicy {
	label {
		display: none !important;
	}
}

.field-validation-error {

	display: block;
	color: $red !important;
	font-family: $aktivGrotesk;
	font-size: .875rem;
	margin-top: .35rem;
}

.checkbox__wrapper {

	position: relative;
	width: auto;
	display: inline-block;
	margin-right: 0;

	&::before {
		content: "\f00c";
		@extend .fas;
		position: absolute;		
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: .75rem;
		color: $white;
		z-index: 1;
		pointer-events: none;
	}

	& + label {
		padding-left: .35rem;
	}
}
