//===================================================================//
// =====================  BASE VARIABLES   ======================== //
//=================================================================//


// DIMENSIONS & GRID
// --------------------------------
$desktop: 1180px;
$tablet: 960px - 1px;
$mobile: 780px - 1px;
$columns: (100% / 12);
$dmargin: 20px / $desktop * 100%;
$mmargin: 10px / $mobile * 100%;
$oneThird:(100/3)*1%;
$twoThird:(100/3)*2%;

$max: 1920px;
$mw: 1500px;
$th: 1000px;
$cw: 900px;
$sw: 700px;
$section: 40px;
$sectionlarge: 60px;
$unit: (1/12)*100%;

// Variables Declaration
// --------------------------------
$openSans: 'open-sans', serif;
$aktivGrotesk: 'aktiv-grotesk', sans-serif;

$gbltxt-hover: #2b6ce5;
$primary-color: #141429;
$secondary-color: #3b3b3b;
$text-color: #141429;

// FONT SIZES
// --------------------------------
$body: 16px;
$bodyMobile: 16px;

$llargText:  24px;
$largText:   20px;
$mediumText: 18px;
$smallText:  14px;
$ssmallText: 12px;



// FONT SPACING
// --------------------------------
$font-space1: 1px;
$font-space2: 2px;
$font-space3: 3px;
$font-space-normal: normal;

// FONT-WEIGHT - $MAIN
// --------------------------------
$slight: 100;
$light: 300;
$regular: 400;
$med: 500;
$sum-medium: 600;
$bold: 700;
$strong: 700;
$weightBlack: 900;

$trans: transparent;

// Base Colours
// --------------------------------
$danger: #ff0000;
$red: #ff0000;
$yellow: #ddd000;
$lblue: #00d2ff;

$w:#ffffff;
$b:#000000;
$white:#ffffff;
$black:#000000;

$blue:#2b6ce5;
$llue:#73c1f4;
$dblue:#141429;
$yellow:#f4b400;
$green: #02782a; 
$purple: #6940a5;
$red: #ff1412;

$dgrey: #828282;
$grey:  #e5e5e5;
$lgrey:  #f1f1f1;
$pink:#ef0592;
$greyFade:rgba($black, 0.1);
$darkGrey:#3c3c3c;

// Fx
// --------------------------------
$shadow:0px 0px 5px 0px #333;  // Global Box Shadow Style (if applicable)
$headShad:0px 0px 10px $black;

// Social
// --------------------------------
$facebook:#3b5998; 
$twitter:#00acee;

$xsmall:15px;
$small:30px;
$medium:45px;
$large:60px;
$xlarge:75px;

//font sizes
$fontSmaller:0.88em; //16px
$fontBody:18px;
$fontLarger:1.11em; //20px
$fontLargerStill:1.22em; //22px
$fontH3:1.44em; //26px;
$fontH2Article:1.55em; //28px
$fontH2:1.77em; //32px
$fontH1:2em; //36px
$fontLargest:3.22em; //58px

//font-weights
$fontMedium:500;
$fontBold:700;
$fontBlack:900;

//breakpoints
$bpLarge:1000px;
$bpMedium:800px;
$bpSmall:500px;

$boxshadow:0 0 12px 5px $greyFade;
