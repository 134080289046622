.globalHeader {    
    position: relative;    
    z-index:9;    

    main{       
        
        .inner{
            display:flex;

            &::after{
                content: none;
                display: inherit;
                clear: inherit;
            }
            
            .wrapperLogo{
                padding: 2*$pad 0;
                .logo {
                    background: none;
                    width: 70px; 
                    display:block;                   
                }
            }   

            .button{
                //@include middle;
                @include vertical-align;
                right:0px;

                ul{
                    display:flex;
                    padding: 0px;
                    margin: 0px;
                    list-style: none;
                    align-items: center;
                    height: 100%;

                    li{
                        padding:0px;
                        margin:0px;                        

                        &.toggle{
                            padding:3*$pad;
                        }
                        a{
                            color:$dblue; 
                            display: block;
                            padding:3*$pad;                           

                            .far{
                                @include font-size(24px, 16px);
                                width: 20px;
                                text-align: center;                               
                            }

                            &:hover{
                                color:$gbltxt-hover;                                
                            }
                        }

                        .toggleSearch{
                            &.active {
                                .fa-search:before {
                                    content: "\f00d";
                                }                                                
                            }              
                        }
                    }
                }
            } 
            
            .top-link{
                @include vertical-align;
                right:95px;                
                //background-color: $dblue;

                ul.top-link-secondary {
                    
                    display: none;

                    li a{
                        color: $dblue;
                        font-weight: $med;
                    }

                    @include media(1024px, min) {
                        display: inline-flex;
                    }
                }

                ul{
                    display:inline-flex;
                    vertical-align: middle;
                    padding: 0px;
                    margin: 0px;
                    list-style: none;

                    li{
                        padding:0px;
                        margin:0px;
                        font-family: $aktivGrotesk;

                        span{
                            display:none;                            
                        }
                        .fal{
                            margin-right:$mar;  
                            @include font-size(24px, 16px);                            
                        }
                        a{
                            color:$dblue;
                            display: block;
                            padding: 3*$pad;

                            &:hover{
                                color:$gbltxt-hover;                                
                            }
                        }
                    }
                }
            }           
        } 

        @media (min-width:1020px){ 
            .inner{
                justify-content:space-between;
                align-items:flex-end;
                position: relative;                

                .button{
                    @include no-vertical-align;
                    margin-top:16*$mar;
                    position: relative;                    

                    ul{ 
                        padding: 0 $pad*2;
                        background-color: $dblue;
                        li{
                            a{
                                color:$white;
                                .far{
                                    @include font-size(18px, 16px);
                                }
                            }
                        }
                    }
                } 

                .wrapperLogo{
                    padding: 3*$pad 0;
                    .logo {
                        width: 100px;                    
                    }
                } 

                .wrapperLogo,
                .wrapperLinks,
                .button{
                    display:flex;                               
                    flex-wrap:wrap;
                    justify-content:flex-end;
                    align-items: end;
                    align-self: stretch;
                }
                .top-link{ 
                    right:15px;
                    top:0px;
                    -webkit-transform: translateY(0);
                    -ms-transform: translateY(0);
                    transform: translateY(0);                    

                    ul:not(.top-link-secondary) {
                        background-color: $dblue;
                    }

                    ul{   
                        padding: 0px 4*$pad;
                        li{
                            span{
                                display:inline-block;                            
                            }
                            .fal{
                                margin-right:$mar;  
                                @include font-size(18px, 16px);                            
                            }    
                            a{
                                color:$white;
                                padding: 3*$pad 2*$pad;
                            }
                        }
                    }
                }
            }
        }
    }
}

.globalHeader + section.body-wrap {
   

    section.gbl-50 {
        padding-bottom: 50px;
        padding-top: 50px;
    }
}