.news-story {

    position: relative;
    @include selfClearing;
    
    .inner{
        position:relative; 

        h2{
            color:$white;
            padding-top:6*$pad;
            padding-bottom:2*$pad;
        }       

        &:before{
            content: '';                
            position: absolute;
            left:-20px;
            top: 0px;
            width:100%;
            height:300px;
            background: url(../images/blue-shap-img2.svg) left top no-repeat;
            background-size: 100%;
        }        
    }

    .scroll-shape {
        right: 13%;
        top: -50px;
        height: 98px;
        width: 98px;
        bottom: unset;
        left: unset;
        background: url(../images/triangle-shap-img2.svg) left top no-repeat;
    }

    @media (min-width:992px){
        .inner{    
            &:before{  
                height:400px;             
                background-size:inherit;
            } 
            
            &.triangle2{
                &:after {
                    left: inherit;
                    right: 13%;
                    top: -50px;
                    height: 98px;
                }
            } 
        } 
    }
}







