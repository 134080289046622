.full-width-img{   
	padding-left:3*$pad;
	padding-right:3*$pad;
	max-width: 1366px;
	margin-left: auto;
	margin-right: auto;

	.page-banner{            
		min-height:300px;            
	}
	p{
		margin:2*$mar 0 0 3*$mar; 
	}
}

.two-column-img{
	position: relative; 
	padding-left:4*$pad;
	padding-right:4*$pad;
	max-width: 1366px;
	margin-left: auto;
	margin-right: auto;

	.row-col{                  
		.column-6{
			@include flex-width(50%);
			padding-left:2*$pad;
			padding-right:2*$pad;
		}
	}
	.page-banner{            
		min-height:160px;
	}
	p{
		margin:2*$mar 2*$mar 0; 
	}	
}

.centre-media, .mceNonEditable.embeditem {

	text-align: center;
	margin: 1rem 0;

	> * {
		display: inline-block;
	}

	img, iframe, video {
		max-width: 100%;
	}

}

@media (min-width:700px){ 
	.full-width-img{		
		.page-banner{            
			min-height:450px;            
		}
	}
	.two-column-img{
		.page-banner{            
			min-height:300px;
		}
	}
}
@media (min-width:992px){
	.full-width-img{
		padding-left:0;
		padding-right:0;
		.page-banner{            
			min-height:600px;            
		}
	}
	.two-column-img{
		padding-left:3*$pad;
		padding-right:3*$pad;

		.row-col {
			.column-6{
				padding-left:15px;
				padding-right:15px; 
			}                 
		}
		.page-banner{            
			min-height:420px;
		}
	}
}

