.joinlisting-page{ 
    .accordion-row {        
        .accordion-head{             	
            margin:0px;	            
        }
    }   
    .inner{
        header{
            margin-bottom:6*$mar;

            h1{
                margin-bottom:0;
            }
        }

        main{     
            
            padding-top: 2rem;

            .listing-type{
                li{
                    margin-bottom:3*$mar;

                    main{
                        .row-col{                            
                            position:relative;
                            overflow: hidden;                            

                            &:before {
                                content: "";
                                position: absolute;
                                right: 50%;
                                z-index: 1; 
                                width: 2px; 
                                height: 100%;
                                background: $grey;
                            }

                            .column-6{
                                padding:0 3*$pad;
                                position:relative;
                                @include flex-width(50%);
                                display: flex;
                                flex-direction: column;
                            }
                        }
                        header{
                            margin-bottom:4*$mar;                
                            h3{
                                margin-bottom:10px;
                            }
                        }
                        main{
                            h6{
                                display:inline-block;
                                border-bottom:1px solid $dblue;
                            }
                            ul{
                                padding:0 0 0 18px;
                                margin-bottom:4*$mar;
                                list-style: disc;
                            }
                        }
                        footer{
                            border-top:2px solid $grey;
                            padding-top:4*$pad;                            
                            align-self: flex-start;
                            margin-top: auto;                           

                            .price{
                                max-width:100%;
                                @include font-size(18px, 16px);
                                padding-bottom:3*$mar;
                                strong{
                                    font-size: 120%;
                                    font-weight: $med;
                                }
                                small{
                                    display:block;
                                    font-size: 70%;
                                    padding-top:$pad;
                                }
                            }                            
                        }
                    }

                    @media (min-width:700px){
                        main{
                            .row-col{
                                margin:0 -6*$mar;
    
                                .column-6{
                                    padding:0 6*$pad;                                 
                                }
                            }
                            footer{ 
                                display: flex;
                                justify-content: space-between;
                                align-items: center;                                
    
                                .price{
                                    max-width: 180px;                                    
                                    strong{
                                        font-size: 140%;                                        
                                    }                                    
                                }                            
                            } 
                        }
                    }
                }
            } 
        } 
    }    
}

#listing-boundary-alert {

    &.visible {
        visibility: visible !important;
    }

    p {
        a {
            display: inline-block;
            width: auto !important;
            margin: 0;
            text-decoration: underline;
            color: $dblue;
        }
    }

}





