.shape{
    content: '';
    position: absolute;    
    z-index: 1;
    width: 0px;
    height: 0px;    
}

// Righr Triangle Shape with:before
[class*=r-triangle-]{
	&:before{
		content: '';                
		position: absolute;
		left:0px;
		top: 0px;
		width:100%;
		height:100%;
		display: block;
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 23%);
	}
}
.r-triangle-yellow{
	&:before{background-color:$yellow;}	
}
.r-triangle-green{
	&:before{background-color:$green;}	
}
.r-triangle-red{
	&:before{background-color:$red;}	
}
.r-triangle-purple{
	&:before{background-color:$purple;}	
}
.r-triangle-blue{
	&:before{background-color:$blue;}	
}
.r-triangle-dblue{
	&:before{background-color:$dblue;}	
}
.r-triangle-lblue{
	&:before{background-color:$lblue;}	
}

// Righr Triangle Shape with:after
[class*=r-shap-triangle-]{
	&:after{
        content: '';                
        position: absolute;
        left:0;
        top:0px;
        z-index: 0; 
        width:100%;
        height:100%;
        clip-path: polygon(41% 0, 100% 14%, 100% 100%, 0 100%, 0 0);
    }
}
.r-shap-triangle-yellow{
	&:after{background-color:$yellow;}	
}
.r-shap-triangle-green{
	&:after{background-color:$green;}	
}
.r-shap-triangle-red{
	&:after{background-color:$red;}	
}
.r-shap-triangle-purple{
	&:after{background-color:$purple;}	
}
.r-shap-triangle-blue{
	&:after{background-color:$blue;}	
}
.r-shap-triangle-dblue{
	&:after{background-color:$dblue;}	
}
.r-shap-triangle-lblue{
	&:after{background-color:$lblue;}	
}



@media (min-width:992px){
	[class*=r-triangle-]{
		&:before{
			clip-path: polygon(0 0, 100% 0, 37% 100%, 0% 100%);
		} 		 
	}
	[class*=r-shap-triangle-]{
		&:after{
			clip-path: polygon(38% 0, 68% 35%, 100% 36%, 100% 100%, 0 100%, 0 0);
		} 		 
	}	

	.triangle{    
        &:after{
            content: '';                
            position: absolute;
            left:0;
            top: 0;
            width:98px;
            height:80px;
            background: url(../images/triangle-shap-img.svg) left top no-repeat;
            background-size: 86%;
        }           
    } 
    .triangle2{  
        @extend .triangle;  
        &:after{       
            background: url(../images/triangle-shap-img2.svg) left top no-repeat;        
        }           
    } 
    .triangle3{  
        @extend .triangle;  
        &:after{ 
            height:95px;      
            background: url(../images/triangle-shap-img3.svg) left top no-repeat;        
        }           
    }
}



