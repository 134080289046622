.itinerary-page{
   @extend .museum-info-page;

    .museum-info{
        .tabs{ 

            nav {
                position: sticky;
                top: 0;
                z-index: 10;
            }

            main.tabsContainer{                       
                ul.tabs{                    
                    li.tab{
                        .tabInfo{
                            &.inside-view{
                                .page-banner{
                                    min-height:250px;
                                    margin-bottom:20px;
                                }
                                .shortInfo{
                                    position: relative;
                                    margin-bottom:30px;
                                }
                                .cards{  
                                    border-bottom:2px solid rgba($black, 0.1);
                                    margin-bottom: 4*$mar;
                                    .card{
                                        figure {
                                            height: 250px;                                            
                                        } 
                                        main {                                            
                                            min-height: auto;  
                                            padding-bottom: 4*$pad; 

                                            .details-info-list {
                                                margin-bottom: 4*$mar;  
                                            } 
                                        }                                       
                                    }
                                    &:last-child{
                                        border-bottom:0;
                                        margin-bottom: 0;
                                    }
                                }
                                @media (min-width:992px){ 
                                    .page-banner{
                                        min-height:300px;
                                    }
                                    .cards{                                
                                        .card{                                
                                            figure {
                                                height: 300px;                                            
                                            }                                        
                                        }
                                    }
                                }
                            }

                            &.map-view{
                                h4{
                                    border-bottom:2px solid $dblue;
                                    @include font-size(22px, 16px);
                                    display: inline-block;
                                    padding-bottom: 5px;
                                    font-weight: $regular;
                                }
                                .map-block{ 
                                    margin-bottom:6*$mar;                                     
                                    img.gh0st {
                                        max-height: 280px;
                                    }                                       
                                } 
                                @extend .inside-view; 

                                .distance-time{
                                    text-align: center;
                                    margin-bottom:4*$mar;

                                    p{
                                        margin:3*$mar 0;
                                    }
                                }                                
                            }

                            &.list-view{
                                h4{
                                    border-bottom:2px solid $dblue;
                                    @include font-size(22px, 16px);
                                    display: inline-block;
                                    padding-bottom: 5px;
                                    font-weight: $regular;
                                }
                                @extend .inside-view;                                
                            }
                        }
                    }
                }
            }
        }
    }
}


@media (min-width:992px){
    .itinerary-page{
        padding-top:6*$pad;

        .row-col{
            .museum-info{
                padding:0 8*$pad;
                right: 50%;
                position: relative;      
                
                .tabs{ 
                    main.tabsContainer{                       
                        ul.tabs{                            
                            li.tab{
                                .tabInfo{
                                    padding:5*$pad;   
                                    
                                    &.map-view{                                        
                                        .map-block{                                      
                                            img.gh0st {
                                                max-height: 350px;
                                            }   
                                        }                  
                                    }
                                }
                            }
                        }
                    }
                } 
            }
            .offerSlider{
                left: 50%;
                position: relative;                    
            } 
        } 
    }
}















