// Button Stylings for Cookies Noti bar

.cta{
    display: inline-block;
    text-align: center;
    text-decoration: none; 
    position: relative; 
    border: 0px; 
    outline: none;     
    color: $w;  
    font-weight:$bold;
    @include font-size(14, 16);
    background-color:$blue;  
    cursor: pointer;
    font-family: $aktivGrotesk;
    font-weight: $med;
    @include padding-all(16.75px, 20px, 16.75px, 20px);     

    &:hover{
        background-color:$secondary-color;
        color:$w;
    }

    &.arrow{
        padding-right:60px !important;
        &:after{
            font-family: 'Font Awesome 5 Pro';
            content: "\f061"; 
            position: absolute;
            right:20px;
            @include vertical-align;
            //@include font-size(16, 16);
        }
    }
    &.mt-30{
        margin-top:6*$mar;
    }
    &.mt-50{
        margin-top:10*$mar;
    }
}

.border-cta{
    display: inline-block;
    text-align: center;
    text-decoration: none; 
    position: relative; 
    border: 2px solid $dblue; 
    outline: none;     
    color: $dblue;  
    @include font-size(14, 16);
    font-weight:$med;
    font-family: $aktivGrotesk;
    cursor: pointer;
    @include padding-all(15px, 20px, 15px, 20px);  
    background: $white;

    &:hover{
        background-color:$dblue;
        color:$white;
    }   
    .far {
        margin-left:$mar;
    }  
    &.mt-30{
        margin-top:6*$mar;
    }
    &.mt-50{
        margin-top:10*$mar;
    } 
}
.dblue-cta{  
    @extend .cta;   
    background-color:$dblue;

    &:hover{
        background-color:$blue;
        color:$w;
    }
}
.green-cta{  
    @extend .cta;   
    background-color:$green;
    color:$dblue;

    &:hover{
        background-color:$blue;
        color:$w;
    }
}

#load-more {

    min-width: 250px;
    margin-top: 1.5rem;
    text-transform: uppercase;
    i {
        display: none;
    }
}





 

