.some-inspiration{   
    .row-col {        
        margin-left:0;
        margin-right:0;

        [class*=column-]{            
            padding-left:0;
            padding-right:0; 
        } 
    } 

    .short-info{    
        background:$blue;  
        color:$white;
        padding:8*$pad;
        margin: 0 -15px;

        h3{        
            @include font-size(30px, 16px);
            color:$white;
            max-width: 700px;  
        }   
    }
    
    .inspire-thum{
        position:relative; 
        overflow: hidden;
        padding: 2*$pad 0 0;
        margin: 0 -3*$pad;        

        &:before{
            content: '';                
            position: absolute;
            left:0px;
            top: 0px;
            width:100%;
            height:100%;
            display: block;
            background:$dblue;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 23%);
        }

        .list-grid-3{
            margin-right: -5px;
            margin-left: -5px;
            padding:0 3*$pad;            
            z-index:2; 

            li{
                padding:$pad; 
                -ms-flex: 0 0 50%;
                    flex: 0 0 50%;
                    max-width: 50%;  
                    
                    &.two-column{
                        -ms-flex: 0 0 100%;
                        flex: 0 0 100%;
                        max-width: 100%;

                        .img-link-card{
                            [class*=-title]{
                                width:49%;        
                            }

                            @include media(700px, max) {
                                figure {
                                    padding-top: 50%;                                    
                                }
                            }
                        }
                    }
            }
        }
    }    

    @media (min-width:700px){        
        .inspire-thum{                                     
            .list-grid-3{
                padding:0;                
                li{                    
                    -ms-flex: 0 0 33.333333%;
                        flex: 0 0 33.333333%;
                    max-width: 0 0 33.333333%;  
                    
                    &.two-column{
                        -ms-flex: 0 0 33.333333%;
                            flex: 0 0 33.333333%;
                       max-width: 0 0 33.333333%;

                       .img-link-card{
                            [class*=-title]{
                                width:100%;        
                            }
                        }
                    }
                }
            }
        }
    }  
    @media (min-width:992px){
        .short-info{
            min-height: 440px; 
            padding:10*$pad;  
            margin: 0;  

            h3{        
                @include font-size(36px, 16px);                 
            }          
        }
        .inspire-thum{
            padding: 8*$pad 0 0 8*$pad;
            margin: 0px;

            &:before{               
                height:440px;
                display: block;
                background: transparent url(../images/blue-shap-img2.svg) left top no-repeat;
                clip-path:inherit;
            } 
        }
    }       
}









