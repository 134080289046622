.paymeny-page{    
    .inner{
        header{
            margin-bottom:8*$mar;

            h1{
                margin-bottom:0;
            }
        }

        .billingForm{
            margin-bottom:4*$mar;
            h4{
                margin:6*$mar 0 2*$mar;
            }

            .business-details{
                .locationValidat{
                    label{
                        display: inline-block;
                        margin-bottom: $mar;
                        @include font-size(14px, 16px);
                        font-weight:$bold;
                    }
                    .inside-location{
                        text-align: center;
                        padding:2*$pad;
                        margin-bottom:3*$mar;
                        @include font-size(14px, 16px);
                        border:2px solid $dblue; 
                    }
                    .outside-location{
                        margin-bottom:3*$mar;                        
                        @include font-size(14px, 16px);
                        header{
                            text-align: center;
                            padding:2*$pad;
                            margin-bottom:0;
                            color:$white;
                            background:$dblue;                           
                        }
                        main{
                            padding:2*$pad 2*$pad 2*$pad 8*$pad;
                            border:2px solid $dblue; 
                            @include font-size(13px, 16px);
                        }
                    }
                    .location-map{
                        overflow: hidden;
                    }
                }
            }

            .card-details{
                h4{
                    margin-bottom:4*$mar;
                }
                .form-group{
                    margin:0 0 3*$mar;                   
                    align-items: center; 
                    //@include selfClearing;                 

                    [class*=txt-]{
                        height: calc(2em + .75rem + 2px);
                        padding: .375rem .75rem;						
                        background-color: $white;
                        background-clip: padding-box;
                        border: 2px solid $lgrey;
                        @include border-radius(5px);
                        transition: border-color .15s ease-in-out,box-shadow .15s  
                    }
                    label{
                        
                        @include flex-width(33%);
                        padding-right:4*$pad;
                        margin-bottom: 0px;
                        text-align: right;
                    }
                    .txt-box{
                        @include flex-width(67%);
                    }
                    .txt-mm, .txt-yy, .txt-cvc{
                        @include flex-width(20%);
                    }
                    .devider{
                        @include flex-width(10%);
                        display: inline-block;
                        text-align:center;
                    }

                    &.group-cta{
                        display: flex;
                        align-items:flex-end; 
                        justify-content: space-between;

                        .green-cta{
                            font-weight:$regular;
                            @include font-size(12, 16);
                            @include border-radius(35px);                            
                            @include padding-all(10px, 20px, 10px, 20px);                            
                        }
                    }

                    #card-exp {
                        min-width: 90px;
                    }

                    #card-cvc {
                        min-width: 55px
                    }

                    #card-number {
                        flex: 0 0 66.66%;
                    }

                }
            }
        }

        .billing-summary{

            font-family: $aktivGrotesk;

            .title{
                border-bottom:1px solid rgba($black, 0.2);
                h4{
                    margin-bottom:3*$mar;
                }                    
            }
            .bill-addr{
                border-bottom:1px solid rgba($black, 0.2);
                padding:3*$pad 0;
                line-height: 24px;

                p{
                    @include font-size(16px, 16px);
                    margin:0px;
                    line-height: 24px;
                }  
            }
            .price{
                border-bottom:1px solid rgba($black, 0.2);
                padding:3*$pad 0;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }
            .total-price{
                padding:3*$pad 0;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                span+span{
                    @include font-size(24px, 16px);
                }
            }
        }
    }    
}

.payment-success {
    margin-top: 50px;
}