.weekend-offer{
	border-bottom:1px solid rgba($black, 0.2);
	padding-bottom:2*$pad;
	margin-bottom:5*$mar;

	[class*=offer-info]{
		padding:4*$pad 24*$pad 4*$pad 4*$pad;
		margin-bottom:8*$mar;
		position: relative;
		width: 90%;
		//background-color:$blue;
		color:$white;
		clip-path: polygon(0 0, 100% 0, 70% 100%, 0% 100%);

		h5{
			border-bottom:1px solid rgba($black, 0.1);
			padding-bottom:2*$pad;
			margin-bottom:2*$mar;
			color:$white;
		}
	} 

	.whatson-offer-info{background-color:$red;}
    .explore-offer-info{background-color:$yellow;}
    .experience-offer-info{background-color:$blue;}
    .shop-offer-info{background-color:$dblue;}
    .taste-offer-info{background-color:$green;}
    .sleep-offer-info{background-color:$purple;}
    .live-offer-info{background-color:$lblue;} 
}