@keyframes animateFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes animateFadeUp {
    0% {
        opacity: 0;
        transform: translateY(50px)
    }
    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@mixin animateFadeIn($duration, $delay : 0s) {
    animation: animateFadeIn $duration ease-in $delay 1 forwards;
}

@mixin animateFadeUp($duration, $delay : 0s, $multiplier : 0) {
    animation: animateFadeUp $duration ease-in #{$delay * $multiplier + 1} forwards;
}


//Cursor animation
#cursor-e {
 
    position: fixed; 
    z-index: 10;
    pointer-events: none;
    mix-blend-mode: darken;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -ms-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
    transform-origin: 0 0;
    @include transition(all, .3s);
    display: none;    

    &::before {
        content: "";
        display: block;
        width: 90px;
        height: 90px;
        margin: -45px;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        @include transition(all, .15s);
        background-image: url(../images/triangle-shap-img.svg);
        background-size: fit;
        background-repeat: no-repeat;            
    }

    &[data-shape="1"] {
        &::before {
            background: url("../images/triangle-shap-img.svg");
        }
    }

    &[data-shape="2"] {
        &::before {
            background: url("../images/triangle-shap-img2.svg");
        }
    }

    &[data-shape="3"] {
        &::before {
            background: url("../images/triangle-shap-img3.svg");
        }
    }

    &.hover {

        @include rotate(0deg);
        @include transition(transform, 2s);
        &::before {
            @include scale(1);
        }
    }

    &.invert {
        mix-blend-mode: lighten;
    }

    &.normal {
        mix-blend-mode: normal;
    }
    
    &.hide {
        &::before {
            transform: scale(0) !important;
        }
    }

    display:none;
	@media (hover: hover) and (pointer: fine) {
	    display:block;
	}

}