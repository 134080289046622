.feature-event {
    @include selfClearing;        
}

section.latest-event {
    padding-bottom: 0 !important
}

.latest-event {
    @include selfClearing;

    .row-col {
        .column-4 {width: 100%;}
        .column-8 {width: 100%;}
    }

    .event-head{

        padding:6*$pad 3*$pad;
        z-index:2;
        position: relative;
        max-width: 300px;
        opacity: 0;

        h3{
            @include font-size(36px, 16px); 
            color:$white;
            margin-bottom:5px;
            font-weight: $bold;
        }
        .txt-link{
            @include font-size(36px, 16px);            
            padding-bottom: 5px;
            color:$blue;
            border-bottom:2px solid $blue;
            font-weight: $bold;  
            display: inline-block;
            font-family: $aktivGrotesk;                     

            .fal{
                @include font-size(24px, 16px); 
            }
        }
    }

    .inner{
        position:relative; 
        overflow: hidden;       

        &:before{
            content: '';                
            position: absolute;
            left:0;
            top: 0px;
            width:110%;
            height:350px;
            background: url(../images/blue-shap-img.svg) left top no-repeat;
            background-size: 110%;
        }        
    }

    .groupSlider {        
        .slider {
            width: calc(100% - 1rem);                      
        }        

        @include media(992px, min) {
            margin:0 0 4*$pad;
        }
    }
    
    @media (min-width:700px){ 
        .event-head{           
            max-width: 500px;             
        }       
        .groupSlider {            
            .slider {
                width: calc(50%); 
                padding: .75rem;
                margin: 0;              
            } 
        }       
    } 
    @media (min-width:992px){
        .row-col {        
            margin-left:0;
            margin-right:0;
    
            [class*=column-]{            
                padding-left:0;
                padding-right:0; 
            } 
        } 
        .event-head{
            padding:6*$pad;   
            max-width: 600px;             
        }
        .inner{
            &:before{                
                height:480px;   
                background-size:inherit;           
            }            
        } 
        .groupSlider {
            margin-top: 35*$pad;            
        }             
    }      
}









