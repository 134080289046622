.map-block{   
    overflow: hidden;
    position: relative;        
    
    img.gh0st {
        width: 100%;
        height: 100%;
        max-height: 400px;
    }

    // Contact Page Map Style
    .mapGrid{
        @include selfClearing;
        overflow: hidden; 
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;  
        background-color: $lgrey;           

        #map{
            height: 100%;
        }              
    }        
} 











