.accordion {	
	padding:0px;
	margin:0px;
	list-style: none;
        
    .accordion-head{ 
		cursor: pointer;
		position: relative;
		background:$dblue;
		color:$white;		
		padding:4*$pad;	
		margin:0px;	
		font-weight:$bold;		
		@include font-size(14px, 16px);
		font-family: $aktivGrotesk;

		&:after{			
			color: $w; 
			content: "\f067"; 
			@include fontAwesome;
			@include vertical-align;                    
			@include font-size(20px, 16px);
			right:20px;
		} 		
		&.active{   
			&:after {
				content: "\f068"; 
			} 
		}
	} 
	.accordion-info{ 
		border:2px solid $dblue;
		padding:6*$pad 3*$pad;

		p{ 
			margin-top:0px;
			&:last-child{ 
				margin-bottom:0px;			
			}		
		}

		ul {
			li {
				font-family: $aktivGrotesk;
			}
		}

		footer {
			div.price {
				font-family: $aktivGrotesk;

				small {
					font-family: $openSans;
				}
			}
		}
	}
	@media (min-width:700px){
		.accordion-info{ 
			padding:6*$pad;                
		}
	} 
}

