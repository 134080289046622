[class*=like-strip]{
    position:absolute;
    right:20px;
    top:15px;
    z-index: 1;
    display: block;
    text-align: center;
    width:50px;
    height: 50px;
    padding-top:2*$pad;
    color:$white;
    @include font-size(24px, 16px);            
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    a{
        color:$white !important;
        font-size: 1.5rem !important;
    }
}
.like-strip{background-color:$red;}
body.whatson .like-strip{background-color:$red;}
body.explore .like-strip{background-color:$yellow;}
body.experience .like-strip{background-color:$blue;}
body.shop .like-strip{background-color:$dblue;}
body.taste .like-strip{background-color:$green;}
body.sleep .like-strip{background-color:$purple;}
body.live .like-strip{background-color:$lblue;}

.like-strip-whatson{background-color:$red;}
.like-strip-explore{background-color:$yellow;}
.like-strip-experience{background-color:$blue;}
.like-strip-shop{background-color:$dblue;}
.like-strip-taste{background-color:$green;}
.like-strip-sleep{background-color:$purple;}
.like-strip-live{background-color:$lblue;}

body .banner-title{
    background-color:$dblue;
    &:after{border-top: 180px solid $dblue;} 
}
body.whatson .banner-title{
    background-color:$red;
    &:after{border-top: 180px solid $red;} 
}
body.explore .banner-title{
    background-color:$yellow;
    &:after{border-top: 180px solid $yellow;} 
}
body.experience .banner-title{
    background-color:$blue;
    &:after{border-top: 180px solid $blue;} 
}
body.shop .banner-title{
    background-color:$dblue;
    &:after{border-top: 180px solid $dblue;}
}
body.taste .banner-title{
    background-color:$green;
    &:after{border-top: 180px solid $green;}
}
body.sleep .banner-title{
    background-color:$purple;
    &:after{border-top: 180px solid $purple;}
}
body.live .banner-title{
    background-color:$lblue;
    &:after{border-top: 180px solid $lblue;}
}

@media (min-width:992px){    
    body .banner-title{
        background-color:$dblue;
        &:after{border-top: 300px solid $dblue;} 
    }
    body.whatson .banner-title{
        background-color:$red;
        &:after{border-top: 300px solid $red;} 
    }
    body.explore .banner-title{
        background-color:$yellow;
        &:after{border-top: 300px solid $yellow;} 
    }
    body.experience .banner-title{
        background-color:$blue;
        &:after{border-top: 300px solid $blue;} 
    }
    body.shop .banner-title{
        background-color:$dblue;
        &:after{border-top: 300px solid $dblue;}
    }
    body.taste .banner-title{
        background-color:$green;
        &:after{border-top: 300px solid $green;}
    }
    body.sleep .banner-title{
        background-color:$purple;
        &:after{border-top: 300px solid $purple;}
    }
    body.live .banner-title{
        background-color:$lblue;
        &:after{border-top: 300px solid $lblue;}
    }
}



body.experience{
    .r-triangle-blue{
        &:before{background-color:$dblue;}	
    }
} 
