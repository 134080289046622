[class*=implicit-grid]{
    margin:0;
    padding:0;
    //margin-top:$small;
    list-style:none;
    width:100%;
    display:grid;
    grid-auto-flow: dense;
    grid-gap:$small $small;
    @include media(800px){
        grid-gap:$small $small;
    }

    li{
        display:flex;
    }
}

.implicit-grid{
    &-4{
        grid-template-columns:1fr 1fr 1fr 1fr;
        @include media(1000px){
            grid-template-columns:1fr 1fr 1fr;
        }
    
        @include media(800px){
            grid-template-columns:1fr 1fr;
        }
        
        @include media(450px){
            grid-template-columns:1fr;
        }
    }

    &-3{
        grid-template-columns:1fr 1fr 1fr;
    
        @include media(800px){
            grid-template-columns:1fr 1fr;
        }
        
        @include media(450px){
            grid-template-columns:1fr;
        }
    }

    &-2{
        grid-template-columns:1fr 1fr;
        
        @include media(768px){
            grid-template-columns:1fr;
        }
    }
}


[class*=list-grid]{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    margin-right: -10px;
    margin-left: -10px;    
    list-style: none;
    position:relative;
    

    li{
        padding:2*$pad;
    }
}

.list-grid{
    &-4, &-3, &-2{
        li{
            @include flex-width(100%);
        }        
    }
    &-4{
        li{
            &.two-column{
                @include flex-width(100%);

                [class*=-title]{
                    width: 49%;        
                }                
            }
        }        
    }    
    
    @media (min-width:700px){
        &-4{
            li{
                @include flex-width(25%);

                &.two-column{
                    @include flex-width(50%);

                    .img-link-card{
                        [class*=title-]{
                            width:49%;      
                        }
                    }
                }
            }        
        } 
        &-3{
            li{
                @include flex-width(50%);
            }        
        }
        &-2{
            li{
                @include flex-width(50%);
            }
        }
    }
    
    @media (min-width:992px){           
        &-3{
            li{
                @include flex-width(33.333333%);                
            }        
        }          
    }
}

[class*=card-title], 
[class*=banner-title]{
    position:absolute;
    left:0px;
    top:0px;
    z-index: 2; 
    display: block;               
    color:$white;
    font-family: $aktivGrotesk;
    font-weight: $med;

    h1, span{
        position:relative;
        z-index:2;
    }
    &:after  {
        content:'';
        position: absolute;
        display:block;        
        top:0px;
        z-index:1;
        width: 0;
        height: 0;        
    }
}
.card-title-yellow{background-color:$yellow !important;}
.card-title-green{background-color:$green;}        
.card-title-blue{background-color:$blue;}
.card-title-dblue{background-color:$dblue;}
.card-title-red{background-color:$red;}



