#onetrust-policy-text {
    font-size: 1rem !important;
}

#onetrust-accept-btn-handler,
#onetrust-pc-btn-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler {
    font-family: $aktivGrotesk !important;
    font-weight: normal !important;
    text-transform: uppercase !important;
    padding: 12px 15px !important;
    color: $white !important;
    text-decoration: none !important;

    &#onetrust-accept-btn-handler,
    &#accept-recommended-btn-handler,
    &.save-preference-btn-handler {
        display: inline-flex !important;
        align-items: center !important;
        text-align: center !important;
        text-decoration: none !important;
        position: relative !important;
        border: 0px !important;
        outline: none !important;
        color: $w !important;
        font-weight: $bold !important;
        font-size: .875rem !important;
        background-color: $blue !important;
        cursor: pointer !important;
        font-family: $aktivGrotesk !important;
        font-weight: $med !important;
        padding: 16.75px 20px !important;
        line-height: 1 !important;
        letter-spacing: unset !important;
        text-transform: none !important;
        height: 50px !important;

        &:hover{
            background-color:$secondary-color !important;
            color:$w !important;
            opacity: 1 !important;
        }
    }

    &#onetrust-pc-btn-handler {
        display: inline-flex !important;
        align-items: center !important;
        text-align: center !important;
        text-decoration: none !important;
        position: relative !important;
        border: 2px solid $dblue !important;
        outline: none !important;
        color: $dblue !important;
        font-size: .875rem !important;
        font-weight: $med !important;
        font-family: $aktivGrotesk !important;
        cursor: pointer !important;
        padding: 16.75px 20px !important;
        background: $white;
        line-height: 1 !important;
        letter-spacing: unset !important;
        text-transform: none !important;
        height: 50px !important;

        &:hover {
            background-color: $dblue !important;
            color: $white !important;
            opacity: 1 !important;
        }        
    }
}

.ot-sdk-show-settings {
    outline: none !important;
    border: none !important;
    color: $black !important;
    padding: 0 !important;
    font-family: "Clydeside-Sans", sans-serif !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 1.9 !important;

    &:hover {
        background: none !important;
        border: none !important;
        outline: none !important;
        color: $red !important;
        opacity: 1 !important;
    }
}

#onetrust-close-btn-container {
    display: none !important;
}
