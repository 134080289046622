body.search-closed {

    main nav ul li:hover  .dropdownGrid{
        visibility: visible;
        opacity: 1;
        @include translateY(0);
    }

}

header.globalHeader{

    opacity: 0;
    @include animateFadeIn(400ms);
    z-index: 10;

    main{        
        nav{        
            @include selfClearing; 
            width:auto;  
            margin-top:16*$mar;      
            align-self: stretch;

            ul{
                @include margin(0px);
                @include padding(0px);               
                height: 100%;            

                li {
                    list-style: none;
                    margin:0 2px 0 0;
                    display: block;
                    float: left;
                    height: auto; 
                    border-top:8px solid $blue; 
                    height: 100%;

                    a{
                        font-family: $aktivGrotesk;
                        color: $text-color;
                        font-weight: $med;
                        display: inline-block;
                        padding:3*$pad $pad;  
                        @include font-size(17px, 16px); 
                        //width:130px;
                        text-align: center;                                                                              
                    } 
                    &:hover > a{
                        color: $text-color;
                    }
                    
                    &.whatson{
                        border-color:$red;
                        .dropdownGrid {            
                            .leftColumns{
                                background: $red;
                                &:before {                                    
                                    background: $red;                                    
                                }                                
                            }                            
                        }
                    }
                    &.explore{
                        border-color:$yellow;
                        .dropdownGrid {            
                            .leftColumns{
                                background: $yellow;
                                &:before {                                    
                                    background: $yellow;                                    
                                }                                
                            }                            
                        }
                    }
                    &.experience{
                        border-color:$blue;
                        .dropdownGrid {            
                            .leftColumns{
                                background: $blue;
                                &:before {                                    
                                    background: $blue;                                    
                                }                                
                            }                            
                        }
                    }
                    &.shop{
                        border-color:$dblue;
                        .dropdownGrid {            
                            .leftColumns{
                                background: $dblue;
                                &:before {                                    
                                    background: $dblue;                                    
                                }                                
                            }                            
                        }
                    }
                    &.taste{
                        border-color:$green;
                        .dropdownGrid {            
                            .leftColumns{
                                background: $green;
                                &:before {                                    
                                    background: $green;                                    
                                }                                
                            }                            
                        }
                    }
                    &.sleep{
                        border-color:$purple;
                        .dropdownGrid {            
                            .leftColumns{
                                background: $purple;
                                &:before {                                    
                                    background: $purple;                                    
                                }                                
                            }                            
                        }
                    }
                    &.live{
                        border-color:$lblue;
                        .dropdownGrid {            
                            .leftColumns{
                                background: $lblue;
                                &:before {                                    
                                    background: $lblue;                                    
                                }                                
                            }                            
                        }
                    }                    

                    ul{
                        li {
                            border-top:0; 
                            float: none;
                        }
                        
                    }                 
                }
                        
            }

            .dropdownGrid {                
                width: 100%;
                position: absolute;
                top: 100%;
                left: 0px;
                z-index: 99;
                display: flex;
                flex-wrap: wrap;
                opacity: 0;
                visibility: hidden;
                transition: transform 0.5s ease, opacity 0.5s ease;
                @include translateY(-20px);

                .leftColumns{
                    //background: $yellow; 
                    flex: 1 0 0%;

                    &:before {
                        position: absolute;
                        content: "";                        
                        width: 100%;
                        height: 100%;
                        left: -100%;
                        top: 0px;
                        background: $yellow;
                    } 
                    
                    .listItem {
                        max-width: 70%;
                        float: right;
                        width: 100%;
                        padding:8*$pad;

                        hr{
                            border-color:$white;
                        }

                        ul{
                            li{
                                padding: $pad 0;
                                color:$white;
                                a{
                                    color:$white;
                                    padding: 0;
                                    font-weight:$regular;
                                    width: unset;                                    

                                    &:hover{
                                        color:rgba($color: $white, $alpha: 0.6);
                                    }
                                }
                            }
                            &.list{
                                @include font-size(20px, 16px);  
                                a{
                                    @include font-size(20px, 16px);                                
                                }                              
                            }
                            &.subList{
                                @include font-size(18px, 16px);
                                a{
                                    @include font-size(18px, 16px);
                                }                                                                 
                            }
                        }                        
                    }
                }
                .rightColumns{
                    flex: 1 0 0%; 
                    padding:6*$pad 3*$pad 0 6*$pad;
                    background:$white;                    
                    
                    &:before {
                        position: absolute;
                        content: "";                        
                        width: 100%;
                        height: 100%;
                        right: -100%;
                        top: 0px;  
                        background: $white; 
                    }

                    .col-row {
                        max-width:610px;                            
                    }
                    
                    .cards{                    
                        .card{ 
                            figure {
                                height: 230px;                                
                            }
                            main {
                                a {
                                    text-align:left;                             
                                }                                
                            }
                        }
                    }
                }
            }

            & + .button {
                li.toggle {
                    display: inline-block;

                    @include media(1020px, min) {
                        display: none;
                    }
                }
            }
        }      
        @media (min-width:1020px){
            nav{
                > ul{ 
                    > li {
                        > a{                            
                            width:100px;                                                                                                        
                        }           
                    }       
                }
            }
        }        
        @media (min-width:1221px){ 
            nav{
                > ul { 
                    > li {
                        > a{                            
                            width:130px;                                                                                                        
                        }           
                    }       
                }
            }
        } 

        nav{
            @include media(1020px) {
                display: none;
            }
        } 
    }       
}

$bar-width: 28px;
$bar-height: 2px;
$bar-spacing: 8px; 
$bar-color: $dblue;

.hamburger-menu {
    margin: auto;
    width: $bar-width;
    height: $bar-height + $bar-spacing*2;
    cursor: pointer;
    
    .bar,
    .bar:after,
    .bar:before {
        width: $bar-width;
        height: $bar-height;
    }
    .bar {
        position: relative;
        transform: translateY($bar-spacing);
        background: $bar-color;
        transition: all 0ms 300ms;
    
        &::before{
            content: "";
            position: absolute;
            left: 0;
            bottom: $bar-spacing;
            background: $bar-color;
            transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);            
        }

        &::after{
            content: "";
            position: absolute;
            left: 0;
            top: $bar-spacing;
            background: $bar-color;
            transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);            
        }
    }
    .mobileMenuOpen & .bar {
        background: transparent; 
    }
    .mobileMenuOpen & .bar::after {
        top: 0;
        transform: rotate(45deg);
        transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
    .mobileMenuOpen & .bar::before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
    }
}

@media(min-width:1020px){
    $bar-color: $white;

    .hamburger-menu {
        pointer-events: none;

        .bar {
            background: $bar-color;        
            &::before{background: $bar-color;}    
            &::after{background: $bar-color;}
        }
    }
}






