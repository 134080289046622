*, *::before, *::after{
    @include box-sizing;
}

::selection {
    background: #efefef;
}

html.mm-blocking{
    overflow: visible;
}
body,
html,
header,
footer,
section,
article,
main,
nav{
    width: 100%;
    display: block; 
    margin: 0;
    padding: 0;
}
input{max-width: 100%;}
blockquote{margin: 0;}

img, svg{
    //display: block;
    height: auto;
    max-width: 100%;
}
a:focus {
    outline: none;
}
body {    
    @include font-size(16, 16);
    font-family: $openSans;    
    font-weight: $regular;
    color: $text-color;
    overflow: auto;
    @include padding(0px);
    @include margin(0px);
    -webkit-font-smoothing: antialiased; 
    -webkit-text-size-adjust: 100%; 
    overflow-x: hidden;    
    overflow-y: auto !important;
    scroll-behavior:smooth;
    @include scrollbars(10px, $blue, $background-color: mix($blue, $white,  20%)); 

    &.menuOpen{
        overflow:hidden;
    }
}
a{
    text-decoration:none;    
    color: $gbltxt-hover;    
    
    &:hover{
        text-decoration:none;
        color: $gbltxt-hover;        
    }
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
}
#window{
    width: 100%;
    min-height: 100%;
    @include transition(transform, .4s); 
    @include selfClearing;
    background-color:$white;
    transition: transform .5s cubic-bezier(.075,.82,.165,1);
}
/*
div#page{
    transition:transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
body.mobileMenuOpen{
    overflow:hidden;

    div#page{
        transform:translateX(-400px);        
    }
}*/

.wrapper{
    width: 100%;
    min-height: 600px;
    @include selfClearing;
}
.container{
    max-width:1360px;
    width:100%; 
    position:relative;
    margin: 0 auto;
    @include selfClearing;
}
.inner{
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1360px;
    @include selfClearing;

    &.small{
        max-width: 790px;        
    }
}

body.listing-application {
    // .body-wrap {
    //     margin-top: 50px;
    // }

    header {
        @include media(991px, max) {
            margin-bottom: 20px !important;
        }
    }

    .inner.gbl-50 {
        padding-bottom: 0;
    }
}

.favourites-page {
    .body-wrap {
        section.two-column-txt:first-of-type {
            padding-top: 50px !important;
        }
    }   
}

.body-wrap{
    min-height:450px;
    @include selfClearing;

    section.two-column-txt:first-of-type {
        padding-top: 0;
        padding-bottom: 0;
    }

    h1, h1 + p {
        opacity: 0;
    }
}
.none{
    display: none;
}
.text-left{text-align:left;}
.text-right{text-align:right;}
.text-center{text-align:center;}

.shadow{
    @include box-shadow(0 0px 5px 0px rgba(0, 0, 0, 0.3));
}

.gbl{
    padding-top: 50px;
    padding-bottom: 50px;
}
.gbl-50{    

    @include media(648px, min) {
        padding-bottom: 50px;
    } 
}

section.gbl-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

header.globalHeader + section.inner-banner.gbl-50 {
    margin-bottom: 50px;
}

.error{border:1px solid $danger !important;} 

.bgBlue{background:$blue;}

input, select{
    padding:0.6em;
    border:1px solid $greyFade;
    border-radius:0;
}

@include media(992px){
    .mob-hide{
        display: none !important;        
    }    
}

// Heading sizes 

h1, h2, h3, h4, h5, h6 {    
    font-family: $aktivGrotesk;
    font-weight: $sum-medium;
    color: $text-color;
    @include margin-all(0, 0, 20px, 0); 
    @include padding(0px);
    position: relative;
    line-height: normal;
}
h1{
    @include font-size(36px, 16px); 
    display: inline-block;
}
h2{
    @include font-size(30px, 16px); 
}
h3{
    @include font-size(24px, 16px); 
}
h4{
    @include font-size(22px, 16px); 
}
h5{
    @include font-size(18px, 16px); 
}
h6{
    @include font-size(17px, 16px); 
}

@media (min-width:992px){
    h1{
        @include font-size(50px, 16px); 
        display: inline-block;
    }
    h2{
        @include font-size(36px, 16px); 
    }
    h3{
        @include font-size(30px, 16px); 
    }
    h4{
        @include font-size(26px, 16px); 
    }
    h5{
        @include font-size(20px, 16px); 
    }
    h6{
        @include font-size(18px, 16px); 
    }
}

img,
video{
    //modern method of achieving background:cover
    &.cover{
        -o-object-fit: cover;
        object-fit: cover;
        @include middle;
        min-width:100%;
        min-height:100%;
    }
}
figure {
    display: block;
    margin: 0 auto;
}

p {
    line-height:20px;
    @include font-size(16px, 16px);

    @include media(1024px, min) {
        @include font-size(16px, 16px);
    }
}

ul li {
    @include font-size(16px, 16px);

    @include media(1024px, min) {
        @include font-size(16px, 16px);
    }
}

ul.unList,
ol.unList{
    list-style-type:none;
    margin:0;
    padding:0;
    display:flex;

    > li{
        display:flex;
    }

    > li > a{
        display:flex;
        width:100%;
        text-decoration:none;
    }
}

@media (min-width:768px) {
    .modal-small {max-width:450px; width:100%;}
    .modal-sm {max-width:650px; width:100%;}
    .modal-md {max-width:850px; width:100%;}
    .modal-lg {max-width:1050px; width:100%;}
}

$pad:5px;
$mar:5px;
$trans:transparent;


.scroll-shape {

    visibility: hidden;
    position: absolute;
    left:10%;
    bottom: 175px;
    width:208px;
    height:190px;
    background: url(../images/triangle-shap-img.svg) left top no-repeat;    
    will-change: transform;

    @include media(992px, min) {
        visibility: visible;
    }
}






