.review-grid{
    position: relative;
    margin-bottom:6*$mar;

    h5{
        position: relative;
        border-bottom:2px solid rgba($black, 0.1);
        margin-bottom:2*$mar;
        padding-bottom:2*$pad;
    }
    h6{
        @include font-size(12px, 16px);
        border-bottom:2px solid rgba($black, 0.1);
        margin-bottom:3*$mar;
        padding-bottom:2*$pad;
    }

    .review-list{
        padding:0px;
        margin:0px;
        list-style: none;        
        
        li{
            border-bottom:2px solid rgba($black, 0.1);
            margin-bottom:3*$mar;
            padding-bottom:3*$pad;

            &:last-child{
                border-bottom:0;
            }

            .review-user{
                @include selfClearing; 
                margin-bottom:3*$mar;
                            
                .user-img{
                    width:50px;
                    height:50px;
                    border: 2px solid $dblue;
                    overflow: hidden;
                    background:$grey;
                    @include border-radius(50%);
                    display: block;
                    margin-right:3*$mar;
                    float: left;
                }
                .review-rate{                
                    display: table;
                    padding-top:$pad;
                    color:$green;
                    .fas{                
                        margin-right:$mar;
                        @include font-size(14px, 16px);
                    }
                }
                .review-date{                
                    display: table;
                    padding-top:$pad;
                    @include font-size(12px, 16px);
                }
            }
            .review-info{
                p{
                    @include font-size(14px, 16px);  
                    margin-top:0px;              
                    &:last-child{
                        margin-bottom:0px;
                    }
                }                
            }
                
            .user{
                position: relative;
            }
            .info{
                position: relative;
            }
        }        
    } 

    .border-cta{
        display:block; 
    }   
}