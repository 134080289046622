.tabs{
    
    nav{

        position: sticky;
        top: 0;
        overflow-x:auto;
        z-index: 11;
        @include scrollbars(5px, $blue, $background-color: mix($blue, $white,  20%));
        @include transition(all, .25s);

        ul{
            margin-bottom:$xsmall;            
            display: flex;
            flex-wrap: nowrap;
            //justify-content: center;
            margin:0px;            

            li{
                padding:2*$pad 6%; 
                background:$dblue;
                color:$white;
                position: relative;
                @include font-size(16px, 16px);
                font-family: $aktivGrotesk;
                font-weight: $med;

                &:not(.active){
                    &:hover{
                        background:$lgrey;
                        color:$dblue;   
                        cursor: pointer;
                    }
                }
                
                &.active{
                    background:$lgrey;
                    color:$dblue;                   
                }
            }
        }

        &.scrolled {
            background: $white;
        }

        #nav-tab-progress {
            position: relative;
            display: block;
            bottom: 0;
            left: 0;
            width: 0;            
            height: 5px;
            overflow-x: hidden;
            max-width: 100%;
            z-index: 1;
            
            &.whatson {
                background: $red;
            }

            &.explore {
                background: $yellow;
            }

            &.experience {
                background: $blue;
            }

            &.shop {
                background: $dblue;
            }

            &.taste {
                background: $green;
            }

            &.sleep {
                background: $purple;
            }

            &.live {
                background: $lblue;
            }

        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 5px;
            background: $lgrey;
            z-index: 0;
        }
    }

    main.tabsContainer{
        background:$lgrey;

        ul.tabs{
            flex-wrap:wrap;
            li.tab{
                width:100%;
                align-items:flex-start;
                z-index: 1;

                @include media($bpMedium){
                    flex-wrap:wrap;
                }

                display:none;
                &.active{
                    display:block;
                }

                .tabInfo{
                    p{
                        margin-top:0px;
                    }
                    p:last-child{
                        margin-bottom:0px;
                    }
                }
            }
        }
    }
}