.interactive-map-header {
    padding-top: 1rem;
    padding-bottom: 2rem;
    padding-left: 15px;
    padding-right: 15px;

    h1 {
        margin-bottom: 0;
    }

    @include media(992px, min) {
        padding-top: unset;
    }
}

.interactive-map-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;    

    #interactive-map-inner {
        position: sticky;
        top: 0;
    }

    #interactive-map {
        width: 100%;
        height: 350px;

        // .gm-style {
        //     > div {
        //         > div {
        //             div:nth-of-type(4) {
        //                 > div {
        //                     canvas {
        //                         box-shadow: 3px 3px 10px rgba(#141429, 0.45);
        //                         border-radius: 50%;
        //                     }

        //                     &:last-of-type {
        //                         canvas {
        //                             box-shadow: none;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }

        @include media(992px, min) {
            height: 500px;
        }

        @include media(1280px, min) {
            height: 600px;
        }

        @include media(1366px, min) {
            height: 650px;
        }
    }

    .map-control {
        position: absolute;
        bottom: 15px;
        right: 15px;
        width: 45px;
        height: 45px;
        background: $dblue;
        box-shadow: 2px 2px 6px rgba($dblue, 0.25);
        cursor: pointer;

        &::before {
            @extend .fas;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $white;
            font-weight: 300 !important;
            font-size: 1.5rem;
        }

        &.zoom-in {
            bottom: 65px;

            &::before {
                content: "\f067";
            }
        }

        &.zoom-out {
            &::before {
                content: "\f068";
            }
        }

        @include media(768px, min) {
            bottom: 20px;
            right: 20px;

            &.zoom-in {
                bottom: 75px;
            }
        }
    }

    @include media(992px, min) {
        padding-left: 15px !important;        
    }
}

.interactive-map-info {
    > main {
        z-index: 10;
        position: sticky;
        top: 0;
        z-index: 20;

        .interactive-map-filter {            

            .map-filter-banner {
                opacity: 0;
                transform: translate3d(0, 100px, 0);
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 5px 20px;
                background: $dblue;
                color: $white;
                font-family: $aktivGrotesk;

                &[data-aos^="fade"][data-aos^="fade"].aos-animate {
                    opacity: 1 !important;
                    transform: translateZ(0);
                }

                > * {
                    flex: 0 0 50%;
                }

                p {
                    font-weight: $bold;
                    font-size: 1rem;
                }

                #filter-toggle {
                    position: relative;
                    text-align: right;
                    font-weight: $bold;
                    font-size: 1rem;
                    cursor: pointer;
                    padding-right: 30px;

                    &::after {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        @extend .fas;
                        content: "\f1de";
                        padding-left: 1rem;
                        font-size: 1.2rem;
                        font-weight: 500;
                    }
                }

                &.open {
                    #filter-toggle {
                        &::after {
                            content: "\f00d";
                        }
                    }
                }
            }

            .map-filter-dropdown {
                display: none;
                list-style: none;
                margin: 0;
                padding: 0;
                background: #fff;

                > li {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

                    header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 0 0 20px;

                        a {
                            color: $dblue;
                            text-decoration: none;
                            font-size: 1.125rem;
                            font-family: $aktivGrotesk;
                            font-weight: $med;
                        }

                        button {
                            border: none;
                            outline: none;
                            width: 45px;
                            height: 45px;

                            &::before {
                                content: "\f067";
                                @extend .fas;
                                color: $white;
                                font-weight: 400;
                            }
                        }
                    }

                    main {
                        display: none;
                        padding: 1rem 0;

                        .filter-sub-list {
                            display: inline-block;
                            min-width: 200px;
                            list-style: none;
                            margin-left: 40px;
                            padding-left: 0;
                            color: $white;
                            padding: 0.5rem 0;
                            border-bottom: 1px solid rgba(255, 255, 255, 0.5);

                            li {
                                font-weight: $bold;
                                font-family: $aktivGrotesk;
                                padding: 0.5rem 0;

                                span {
                                    position: relative;
                                    top: 2px;
                                    display: inline-block;
                                    vertical-align: top;
                                    width: 15px;
                                    height: 15px;
                                    background: transparent;
                                    border: solid 2px $white;
                                    margin-right: 0.5rem;
                                    cursor: pointer;
                                }

                                p {
                                    display: inline-block;
                                    width: 80%;
                                    padding: 0;
                                    margin: 0;
                                    font-size: 1rem;
                                }

                                &.active {
                                    span {
                                        background: $white;
                                    }
                                }
                            }

                            @include media(992px, min) {
                                min-width: 250px;
                                max-width: 400px;
                            }
                        }
                    }

                    &.open {
                        header {
                            button {
                                &::before {
                                    content: "\f068";
                                }
                            }
                        }

                        main {
                            display: block;
                        }
                    }

                    &.whatson {
                        button,
                        main {
                            background: $red;
                        }
                    }

                    &.explore {
                        button,
                        main {
                            background: $yellow;
                        }
                    }

                    &.experience {
                        button,
                        main {
                            background: $blue;
                        }
                    }

                    &.shop {
                        button,
                        main {
                            background: $dblue;
                        }
                    }

                    &.taste {
                        button,
                        main {
                            background: $green;
                        }
                    }

                    &.sleep {
                        button,
                        main {
                            background: $purple;
                        }
                    }

                    &.live {
                        button,
                        main {
                            background: $lblue;
                        }
                    }

                    &.more {
                        button,
                        main {
                            background: transparent;
                        }

                        main {
                            .filter-sub-list {
                                border-color: $dblue;
                                color: $dblue;

                                li.active {
                                    span {
                                        background: $dblue;
                                    }
                                }

                                span {
                                    border-color: $dblue;
                                }
                            }
                        }

                        button::before {
                            color: $dblue;
                        }
                    }
                }
            }
        }

        .interactive-map-legends {
            padding: 5px 20px;
            opacity: 0;
            background: #fff;

            ul {
                padding-left: 0;
                list-style: none;
                padding-top: 1.25rem;
                padding-bottom: 0.5rem;
                border-top: solid 1px rgba($dblue, 0.1);
                border-bottom: solid 1px rgba($dblue, 0.1);

                li {
                    display: inline-block;
                    vertical-align: middle;
                    font-weight: $med;
                    font-family: $aktivGrotesk;
                    font-size: 0.875rem;
                    padding-right: 0.75rem;
                    padding-bottom: 0.75rem;

                    &::before {
                        content: "";
                        position: relative;
                        display: inline-block;
                        vertical-align: middle;
                        top: -2px;
                        width: 12px;
                        height: 12px;
                        margin-right: 0.5rem;
                    }

                    &.explore::before {
                        background: $yellow;
                    }

                    &.experience::before {
                        background: $blue;
                    }

                    &.shop::before {
                        background: $dblue;
                    }

                    &.taste::before {
                        background: $green;
                    }

                    &.sleep::before {
                        background: $purple;
                    }

                    &.live::before {
                        background: $lblue;
                    }

                    &.whatson::before {
                        background: $red;
                    }

                    &.more::before {
                        border: solid 2px $dblue;
                    }
                }
            }
        }

        @include media(992px, min) {
            &-legends,
            &-cards {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    @include media(992px, max) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.interactive-map-cards {

    padding: 0 20px;

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        margin-top: 0;

        li.map-card-item {
            display: none;
            position: relative;
            flex: 0 0 calc(50%);
            padding: 0.35rem;

            @for $i from 1 through 4 {
                &:nth-of-type(#{$i}) {
                    display: block;
                }
            }

            &[data-category="whatson"] {
                .like-strip {
                    background: $red;
                }
            }

            &[data-category="explore"] {
                .like-strip {
                    background: $yellow;
                }
            }

            &[data-category="experience"] {
                .like-strip {
                    background: $blue;
                }
            }

            &[data-category="shop"] {
                .like-strip {
                    background: $dblue;
                }
            }

            &[data-category="taste"] {
                .like-strip {
                    background: $green;
                }
            }

            &[data-category="sleep"] {
                .like-strip {
                    background: $purple;
                }
            }

            &[data-category="live"] {
                .like-strip {
                    background: $lblue;
                }
            }

            &[data-category="more"] {
                .like-strip {
                    background: $white;

                    i {
                        color: $dblue;
                    }
                }
            }

            .like-strip {
                cursor: pointer;

                @include media(768px, min) {
                    top: calc(15px + 0.75rem);
                    right: calc(20px + 0.75rem);
                }
            }

            figure {
                position: relative;
                padding-top: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            h3 {
                padding: 0.5rem 0;
                margin: 0;
            }

            @include media(768px, min) {
                flex: 0 0 33.33%;
            }

            @include media(992px, min) {
                flex: 0 0 100%;
            }

            @include media(1024px, min) {
                flex: 0 0 50%;

                &:nth-of-type(1), &:nth-of-type(2) {
                    padding-top: 0;
                }
            }

            @include media(1366px, min) {                
                padding: 0.75rem;

                @for $i from 1 through 2 {
                    &:nth-of-type(#{$i}) {
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .card-item-popup {
        display: none;
        position: fixed;
        left: 0;
        bottom: 0;
        flex-direction: row;
        align-items: flex-start;
        width: 100vw;
        max-height: 300px;
        height: auto;
        z-index: 151;
        background: $white;
        padding: 30px 15px;
        @include transition(all, 0.2s);

        .close-item-popup {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;

            &::before {
                @extend .fas;
                content: "\f00d";
                font-weight: 400;
            }
        }

        a.popup-arrow {
            position: absolute;
            bottom: -1rem;
            right: 0;
            color: $black;

            &::before {
                content: "\f061";
                @extend .fas;
            }
        }

        .item-popup-img,
        main {
            position: relative;
            flex: 0 0 50%;                    
        }

        figure {

            position: relative;
            height: 100%;
            width: 100%;
            padding-top: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        main {

            padding: 0 0.75rem;
            
            h3 {
                font-weight: 400;
                padding-bottom: .25rem;
                border-bottom: solid 1px rgba(#000, .1);
                margin-bottom: 10px;
                @include font-size(24, 16);
                margin-top: .25rem;
            }

            .item-rating {
                position: relative;
                font-size: 10px;
                font-family: $aktivGrotesk;
                padding-left: 1.5rem;

                &::before {
                    content: "\f005";
                    @extend .fas;
                    position: absolute;
                    top: 45%;
                    transform: translateY(-50%);
                    left: 0;
                    font-weight: bold;
                }
            }

            .price {
                font-family: $aktivGrotesk;
                margin-top: 0;
                font-weight: $bold;
                font-size: 1rem;

                span {
                    display: block;
                    font-weight: $med;
                }
            }

            .group-icon {
                
                padding-right: 1.25rem;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    vertical-align: middle;
                    padding-right: 0.5rem;

                    img {
                        width: 20px;
                    }
                }
            }            
        }

        &.open {
            display: flex;
        }

        &.whatson {
            .like-strip {
                background: $red;
            }
        }

        &.explore {
            .like-strip {
                background: $yellow;
            }
        }

        &.experience {
            .like-strip {
                background: $blue;
            }
        }

        &.shop {
            .like-strip {
                background: $dblue;
            }
        }
        
        &.taste {
            .like-strip {
                background: $green;
            }
        }

        &.sleep {
            .like-strip {
                background: $purple;
            }
        }

        &.live {
            .like-strip {
                background: $lblue;
            }
        }

        @include media(550px, min) {
            position: fixed;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 450px;
            transform: translateY(-50%);
        }
    }

    #map-cards-more {
        text-align: center;
    }

    @include media(992px, min) {
        max-height: 1000px;
        overflow-y: auto;
        padding: 0;
    }
}

body.map-item-open {
    .card-item-popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($dblue, 0.3);
        z-index: 100;
    }
}
