.like-dislike{
    display:inline-block;
    margin-right:6*$mar;
    a{
        display:inline-block;
        width: auto;
        color:$dblue;
        margin-left:2*$mar;

        &.like-no{
            color:$green;
        }
    }                  
}

.addFavorites {
    &.liked {
        > i {
            font-weight: 900 !important;            
        }
    }
}