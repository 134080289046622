section.banner {
    min-height: 550px;
    position: relative;
    margin-bottom: 60px;

    opacity: 0;
    transform: translateY(50px);
    @include animateFadeUp(350ms, 150ms);

    .slider {
        figure {
            max-height: 350px;
            overflow: hidden;
        }
        img.cover {
            height: 100%;
        }
    }

    .banner-shape {
        position: absolute;
        right: 0px;
        bottom: -40px;
        z-index: 1;
        width: 170px;
        height: 80px;
        overflow: hidden;
    }

    .slideSet {
        .banner-content {
            width: 100%;
            padding: 3 * $pad;
            background: $white;

            h1 {
                margin-bottom: 0;
                letter-spacing: 1px;
            }
            p {
                margin-bottom: 4 * $pad;
                line-height: 24px;
            }

            &:after {
                display: none;
                @extend .shape;
            }
        }

        .slider {
            figure {
                position: relative;
                
                @include media(700px, min) {
                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }

        .flickity-prev-next-button.previous {
            transform: inherit;
            background: rgba(2, 120, 42, 1);
            left: inherit;
            right: 65px;
            top: unset;
            bottom: 15px;
            z-index: 2;
            width: 50px;
            height: 50px;

            &:after {
                @include font-size(24px, 16px);
            }

            @include media(992px, min) {
                top: inherit;
                bottom: 0;
            }
        }

        .flickity-prev-next-button.next {
            transform: inherit;
            background: rgba(20, 20, 41, 1);
            right: 15px;
            top: unset;
            bottom: 15px;
            z-index: 2;
            width: 50px;
            height: 50px;

            &:after {
                @include font-size(24px, 16px);
            }

            @include media(992px, min) {
                top: inherit;
                bottom: 0;
            }
        }
    }

    @media (min-width: 700px) {
        margin-bottom: 80px;

        .slider {
            figure {
                min-height: 450px;
                max-height: 450px;
            }
        }
    }

    @media (min-width: 992px) {        

        .banner-shape {
            height: 100px;
        }
        .slider {
            figure {
                min-height: 550px;
                max-height: 550px;
                max-width: 64%;
                margin-right: 0;
            }
        }
        .slideSet {
            .banner-content {
                width: 36%;
                height: 100%;
                position: absolute;
                left: 0px;
                top: 0px;
                z-index: 9;
                padding: 10 * $pad 0px 10 * $pad 10 * $pad;

                &:after {
                    right: -299px;
                    top: 0px;
                    display: block;
                    border-top: 550px solid $white;
                    border-right: 300px solid transparent;
                }

                h1 {
                    @include font-size(55px, 16px);
                }
            }
            .flickity-prev-next-button.previous {
                left: 40%;
                bottom: 0px;
                width: 70px;
                height: 70px;

                &:after {
                    @include font-size(30px, 16px);
                }
            }

            .flickity-prev-next-button.next {
                right: inherit;
                left: 40%;
                bottom: 70px;
                width: 70px;
                height: 70px;

                &:after {
                    @include font-size(30px, 16px);
                }
            }
        }
    }
}

section.inner-banner {
    @extend .page-banner;
    min-height: 350px;

    opacity: 0;
    transform: translateY(50px);
    @include animateFadeUp(350ms, 150ms);

    h1 {
        color: $white;
        margin-bottom: 0px;
    }

    [class*="banner-title"] {
        height: 180px;
        max-width: 150px;
        padding: 4 * $pad $pad 0 3 * $pad;

        &:after {
            right: -200px;
            border-left: 0px solid transparent;
            border-right: 200px solid transparent;
        }
    }
    .banner-title-yellow {
        &:after {
            border-top: 180px solid $yellow;
        }
    }
    .banner-title-green {
        &:after {
            border-top: 180px solid $green;
        }
    }
    .banner-title-blue {
        &:after {
            border-top: 180px solid $blue;
        }
    }
    .banner-title-dblue {
        &:after {
            border-top: 180px solid $dblue;
        }
    }
    .banner-title-red {
        &:after {
            border-top: 180px solid $red;
        }
    }
    .banner-title-purple {
        &:after {
            border-top: 180px solid $purple;
        }
    }
    .banner-title-lblue {
        &:after {
            border-top: 180px solid $lblue;
        }
    }

    @media (min-width: 992px) {
        min-height: 450px;

        h1 {
            @include font-size(55px, 16px);
        }

        [class*="banner-title"] {
            height: 300px;
            max-width: 230px;
            padding: 6 * $pad $pad 0 6 * $pad;

            &:after {
                right: -300px;
                border-right: 300px solid transparent;
            }
        }
        .banner-title-yellow {
            &:after {
                border-top: 300px solid $yellow;
            }
        }
        .banner-title-green {
            &:after {
                border-top: 300px solid $green;
            }
        }
        .banner-title-blue {
            &:after {
                border-top: 300px solid $blue;
            }
        }
        .banner-title-dblue {
            &:after {
                border-top: 300px solid $dblue;
            }
        }
        .banner-title-red {
            &:after {
                border-top: 300px solid $red;
            }
        }
        .banner-title-purple {
            &:after {
                border-top: 300px solid $purple;
            }
        }
        .banner-title-lblue {
            &:after {
                border-top: 300px solid $lblue;
            }
        }
    }
}

#not-found-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 

    main {
        flex: 0 0 100%;

        h1 {
            font-size: 6.125rem;
            margin: 0;
            line-height: 1;
        }

        h4 {
            font-size: 2.05rem;
            line-height: 1.25;
            padding: 1.75rem 0;
        }
    }

    figure {

        position: relative;
        display: none;

        img:first-of-type {
            position: absolute;
            top: 0;
            left: 2rem;
            width: 120px;
            transform: rotate(150deg);
        }

        img:last-of-type {
            width: 80%;            
            height: auto;
            transform: rotate(100deg);
            float: right;
        }
    }

    @include media(1024px, min) {

        padding-left: 2rem;
        padding-right: 2rem;

        figure {
            display: block;
            flex: 0 0 55%;
            padding-left: 1.5rem;
        }

        main {
            flex: 0 0 45%;
            padding-right: 1.5rem;

            h1 {
                font-size: 8.125rem;
            }
        }
    }

    @include media(1280px, min) {

        padding-left: 6rem;

        figure {
            padding-left: 3rem;
        }

        main {
            padding-right: 3rem;
        }
    }

    @include media(1536px, min) {
        padding-left: 8rem;
    }
}