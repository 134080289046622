// Bread Crumbs
ul.breadcrumbs{
    opacity: 0;
    padding: 0px;
    margin: 0 0 2*$mar;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li{
        position: relative;
        font-weight: $med;        
        @include font-size(14px, 16px); 
        padding-right:$pad;
        margin-right:$mar;
        font-family: $aktivGrotesk;
        
        &:after{
            content: "/"; 
            @include vertical-align;
            right:0px;            
        }
        
        &:last-child{
            margin-right: 0px; 
            text-decoration: underline;

            &:after{
                content: none;                         
            }
        }
        a{            
            position: relative;
            color:$dblue;
            padding-right:$pad;
            
            &:hover{
                text-decoration: underline;
            }
        }
    }
}