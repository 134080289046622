nav#mobileMenu{
    background:$white;
    position:fixed;
    display:flex;
    padding:0 0 $xsmall;
    top:0;
    right:0;
    height:100%;
    width:auto;
    min-width:350px;
    transform:translateX(100%);
    z-index:100;
    transition:transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    overflow:auto;
    @include scrollbars(8px, $green, $background-color: mix($green, $white,  20%));
    font-family: $aktivGrotesk;

    a{
        color:$dblue;
        text-decoration:none;
        @include font-size(18px, 16px);
        ;
    }
    
    .inner{
        display:flex;
        flex-direction:column;
        width:100%;
        padding:0 0 $small;
    }
    
    body.mobileMenuOpen &{
        transform:translateX(0);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
    }

    header,
    main,
    footer{
        padding:$xsmall 0;
    }

    header.menuHeader{
        display:flex;
        justify-content:space-between;
        align-items:center;
        color:$white;
        font-size:2em;
        background:$dblue;
        padding:7*$pad 4*$pad;

        button.close{
            @extend .fa-arrow-left;
            width:$medium;
            height:$medium;
            background:transparent;
            border:0px;
            outline: none;
            @include transition-all(0.30s);
            &:focus{
                border:0px;
                outline: none;
            }
            &::before{
                @extend .far;
                color:$white;
            }
            &:hover {
                @include transition-all(0.30s);
                @include rotate(180deg);                
              } 
        }
    }

    main{
        padding-top:0px;
        
        nav{
            ul{
                &.l0{flex-direction:column;}                
                &.l1{flex-direction:column;}
            }
    
            li{
                &.l0{
                    flex-direction:column;
                    border-bottom:1px solid rgba($black, 0.25);
                }

                &.whatson{
                    header{            
                        button{background:$red;}                        
                    }
                    main{
                        background:$red;
                        padding:4*$pad;
                        ul{                            
                            border-bottom:1px solid $white;
                            padding-bottom:2*$pad;
                            li{
                                a{
                                    padding:$pad 0;
                                    display: block;
                                    @include font-size(14px, 16px);
                                    font-weight:$bold;
                                    color:$white;
                                }
                            } 
                        }
                    }
                }
                &.explore{
                    @extend .whatson;                    
                    header{ 
                        button{background:$yellow;}                        
                    }
                    main{background:$yellow;}                    
                }
                &.experience{
                    @extend .whatson;                    
                    header{ 
                        button{background:$blue;}                        
                    }
                    main{background:$blue;}
                }
                &.shop{
                    @extend .whatson;                    
                    header{ 
                        button{background:$dblue;}                        
                    }
                    main{background:$dblue;}
                }
                &.taste{
                    @extend .whatson;                    
                    header{ 
                        button{background:$green;}                        
                    }
                    main{background:$green;}
                }
                &.sleep{
                    @extend .whatson;                    
                    header{ 
                        button{background:$purple;}                        
                    }
                    main{background:$purple;}
                }
                &.live{
                    @extend .whatson;                    
                    header{ 
                        button{background:$lblue;}                        
                    }
                    main{background:$lblue;}
                }
                &.more{
                    @extend .whatson;                    
                    header{ 
                        button{
                            background:$white;
                            &::before{
                                color:$dblue;
                            }
                        }                 
                    }
                    main{
                        background:$white;
                        padding:0 4*$pad;                        
                        ul{
                            border-bottom:0;                                                       
                            li{
                                a{                                    
                                    color:$dblue;
                                }
                            } 
                        }
                    }
                }
                &.additional {

                    padding-top: calc(23px / 2);
                    padding-bottom: calc(23px / 2);

                    header {

                        flex-wrap: wrap;

                        a {
                            display: block;
                            flex: 0 0 100%;
                            padding: .5rem 0;
                        }
                    }
                }
            }
        }

        header{
            width:100%;
            display:flex;
            justify-content:space-between;
            align-items:center;
            padding:0 0 0 4*$pad;

            button{
                width:$medium;
                height:$medium;
                border:0px;
                @extend .fa-plus;
                &::before{
                    @extend .far;
                    color:$white;
                }

                &:focus{
                    border:0px;
                    outline:none;
                }
            }
            
            &.active{
                button{
                    @extend .fa-minus;
                }
                & + main{
                    display:block;
                }
            }
        }

        main{
            padding:0;
            display:none;
        }
    }

    footer{

        //Hidden on front-end as requested by Michael
        display: none;

        padding:0 3*$pad 4*$pad; 
        @include font-size(14px, 16px);
        a{
            margin:$pad;
            @include font-size(14px, 16px);           
        }
        p{
            margin:$pad 0;
        }
    }
}