.social-link {
    position: relative;

    ul {
        display: inline-flex;
        flex-direction: row;
        align-items: stretch;
        column-gap: 3 * $pad;
        row-gap: 2 * $pad;
        padding: 0px;
        margin: 0px;

        li {
            display: inline-block;
            position: relative;
            vertical-align: top;

            a {
                color: $white;
                background: $dblue;
                width: 46px;
                height: 46px;
                display: block;
                position: relative;

                &:hover {
                    background: $blue;
                }

                .fab,
                .fas {
                    @include middle;
                    @include font-size(20px, 16px);
                }

                svg {
                    @include middle;
                    width: 24px;
                    height: 24px;
                    fill: #fff;
                }
            }

            [class*='cta'] {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 100%;
                min-height: 46px;
                font-size: 0.875rem !important;
            }
        }
    }
}

a.globe-icon {
    color: $white;
    background: $dblue;
    width: 46px;
    height: 46px;
    display: block;
    position: relative;

    &:hover {
        background: $blue;
    }

    .fab,
    .fas {
        @include middle;
        @include font-size(20px, 16px);
    }

    @include media(992px, min) {
        width: 54px;
        height: 54px;
    }
}

.share-this {
    display: inline-flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    position: relative;
    border: 1px solid $dblue;
    outline: none;
    color: $dblue !important;
    @include font-size(24px, 16px);
    font-weight: $bold;
    cursor: pointer;
    padding: 0 !important;
    background: transparent !important;
    width: 46px;
    height: 46px;

    .at-icon-wrapper {
        background-color: transparent !important;
    }

    i {
        @include middle;
    }

    span {
        @include font-size(14px, 16px);
        margin-left: 2 * $mar;
    }
    &:hover {
        background-color: $dblue;
        color: $white;
    }
    &.mt-20 {
        margin-top: 4 * $mar;
    }
    &.mt-30 {
        margin-top: 6 * $mar;
    }

    @include media(992px, min) {
        width: 54px;
        height: 54px;
    }
}

@media (min-width: 992px) {
    .social-link {
        ul {
            li {
                a {
                    width: 54px;
                    height: 54px;
                    .fab,
                    .fas {
                        @include font-size(24px, 16px);
                    }
                }
            }
        }
    }
}
