.our-picks {
    overflow: hidden;
    position:relative; 
    padding: 2*$pad 0 4*$pad 0 !important;   
    margin-top: 4*$pad;     
    
    .inner{
        position:relative; 
        z-index: 2; 

        h2{
            color:$white;
            padding-top:4*$pad;
            padding-bottom:2*$pad;
        }                  
    }
    .cards{    
        .card{
            main {
                color: $white; 
                h3 {
                    color: $white;
                    font-weight:$regular;                 
                }                
            }    
        }
    }

    .border-cta{        
        border: 1px solid $white; 
        color: $white;
    
        &:hover{
            background-color:$white;
            color:$dblue;
        }
    }

    .scroll-shape {
        top: 0;
        left: 52%;
        width: 98px;
        height: 80px;
        background: url(../images/triangle-shap-img.svg) left top no-repeat;
        background-size: 86%;
    }

    @media (min-width:992px){
        padding: 2*$pad 0 6*$pad 0 !important;   
        margin-top: 10*$pad;              

        .inner{    
            &:after{               
                left:52%;                
            }           
        } 
    } 
}







