.noticepost-page{    
    .inner{
        header{
            position:relative;
            margin-bottom:10*$mar;             

           .row-col{
                .text-right{
                    text-align: left;
                }

                @media (min-width:992px){
                    .text-right{
                        text-align: right;
                    } 
                }
            }
        }
    }
}








