.prev-next-disabled {
    .flickity-prev-next-button.next, .flickity-prev-next-button.previous {
        cursor: default;
        opacity: .25;
        pointer-events: none;
    }
}

.slideSet{
    .slider {
        width: 100%;
        margin-right: 0px;     
        //counter-increment: slider;
    }

    .flickity-prev-next-button{
        width: 72px;
        height: 72px;
        border-radius: 0;
        background: rgba(0,0,0,0.75);
        @include padding(0px);

        svg{
            display: none !important;
        }
    }

    .flickity-prev-next-button.previous {
        left: 0px;

        &:after{
            font-family: 'Font Awesome 5 Pro';
            content: "\f104"; 
            position: absolute;
            @include middle;
            color: $w; font-size: 40px;
        }
    }

    .flickity-prev-next-button.next{
        right: 0px;

        &:after{
            content: "\f105"; 
            font-family: 'Font Awesome 5 Pro';
            position: absolute;
            @include middle;
            color: $w; 
            font-size: 40px;
        }
    }    
}

.groupSlider {
    @extend .slideSet;
    z-index:1;
    .slider {

        width: calc(100% - 1rem);
        padding: 0 .5rem;
        margin: 0;
    }  

    .flickity-prev-next-button{
        display:none;   
    }

    .flickity-prev-next-button{
        width: 40px;
        height: 40px;
        background: rgba(0,0,0,0.0);
        border:1px solid $black; 
        z-index: 1;      
    }

    .flickity-prev-next-button.previous {
        left: inherit;
        right: 65px;
        top:-40px;  

        &:after{
            color: $b; font-size: 20px;
        }      
    }

    .flickity-prev-next-button.next{
        right: 12px;  
        top:-40px;   

        &:after{
             color: $b; font-size: 20px;
        }
    }

    @media (min-width:650px){
        margin: 0 -10px;        
        .slider {
            width: calc(50%);                       
        }    
        .flickity-prev-next-button{
            display:inline-block;   
        }
    } 
    @media (min-width:992px){
        margin: 0 -10px;
        margin-bottom:20px;
        .slider {
            width: calc(33.333%);                       
        }
    } 
}


.thumbnailSlider{

	.cell-item{
		width: 100%;            
		margin-right: 20px;
		margin-bottom: 0px;		
		text-align: center;

        figure {
            position: relative;
            padding-top: 66.66%;

            img,iframe,video {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;                
            }

            img {
                object-fit: cover;
            }
        }		

        &.video {
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 40%;
                width: 100%;                
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 15%;
                left: 0;
                width: 100%;
                height: 30%;
            }
        }

        [class*=card-title]{    
            height:70px;
            max-width:80px;    
            padding:2*$pad $pad 0 2*$pad;
            text-align: left;
            @include font-size(18px, 16px);
            background-color: $yellow;
            
            &:after{
                right:-60px;        
                border-left: 50px solid transparent;
                border-right: 60px solid transparent;
                border-top: 70px solid $yellow;
            }
        }

        [class*=like-strip]{
            position:absolute;
            right:20px;
            top:15px;
            z-index: 1;
            display: block;
            text-align: center;
            width:50px;
            height: 50px;
            padding-top:2*$pad;
            color:$white;
            @include font-size(24px, 16px);            
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
        }	
	}
	
	.flickity-prev-next-button{
		display:none;
	}
    .flickity-page-dots {        
        
        position: relative !important; 
        bottom: 0 !important;
        margin-top: 1rem;

        @include media(992px, min) {
            bottom: 50px !important;             
            position: absolute !important;
            margin: 0rem;
        }

        .dot {           
            width: 14px;
            height: 14px;            
            background: transparent;
            margin:0 4px;         
            opacity:1;
            border:2px solid $dblue;

           &.is-selected {
                background: $dblue;
            }
        }      
    }

	.gallery-video {
		position: relative;
		overflow: hidden;
		width: 100%;
		padding-top: 48%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

		iframe{
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height:100%;
		}
	}

    .larg-slider-nav{
        display:none;  
    }

    .larg-slider {
        .cell-item {
            figure {
                padding-top: 75% !important;
            }
        }
    }

	@media(min-width:992px){         
        .larg-slider-nav{
            display:block;
            .cell-thumb-item {
                height: 130px;
                width: 190px;
                margin-right:3*$mar;
                background-color: $lgrey; 
                text-align: center;  
                counter-increment: carousel-cell;
                opacity:0.6;                
    
                &.is-nav-selected {
                    opacity:1;  
                }
            }
            
        }

        .cell-item{
            margin-bottom: 20px;            
    
            [class*=card-title]{    
                height:100px;
                max-width:150px;    
                padding:3*$pad $pad 0 3*$pad;                
                @include font-size(24px, 16px); 
                &:after{
                    border-top: 100px solid $yellow;
                }                
            }                     	
        }		
	}
	
}

.thumbnail-image{
    position:relative;
    height: 350px;
    width: 100%;
    overflow: hidden;	

    [class*=card-title]{
        height:70px;
        max-width:80px;    
        padding:2*$pad $pad 0 2*$pad;
        text-align: left;
        @include font-size(18px, 16px);
        background-color: $yellow;
        
        &:after{
            right:-60px;        
            border-left: 50px solid transparent;
            border-right: 60px solid transparent;
            border-top: 70px solid $yellow;
        }
    }    	

	@media(min-width:992px){
        height: 450px;

        [class*=card-title]{
            height:100px;
            max-width:150px;    
            padding:3*$pad $pad 0 3*$pad;                
            @include font-size(24px, 16px); 
            &:after{
                border-top: 100px solid $yellow;
            }                
        }		
	}	
}

#divHomeFeaturedEvents.re-init {
    .cards {
        opacity: 0;
        transform: translateY(60px);
        animation: animUp .35s ease-in-out forwards;
    }
}

@keyframes animUp {
    0% {
        opacity: 0;
        transform: translateY(60px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}







