.split-banner{    
    background:#417be8 url(../images/dome-shap-img.png) 0 0;  

    .inner{        
        padding:10*$pad 4*$pad;    
    } 
    
    h3{        
        @include font-size(26px, 16px);
        color:$white;
        max-width: 700px;  
        margin-bottom:8*$mar;    
    }

    .cta{            
        color: $dblue;  
        background-color:$white;           
    
        &:hover{
            background-color:$dblue;
            color:$w;
        }
    }

    @media (min-width:700px){
        .inner{        
            padding: 14*$pad;    
        } 
        h3{        
            @include font-size(46px, 16px);              
        }
    }
}









