.filter-result{    
    position:relative; 

    .inner{ 
        position:relative;
    }

    h2.none{ 
        display:block;
    }

    .list-grid{
        &-4, &-3, &-2{
            li{    
                .cards{
                    .card{
                        main{
                            ul.details-info-list {
                                li {                    
                                    padding:0px;
                                    -ms-flex: inherit;
                                    flex: inherit;
                                    max-width: inherit;
                                }
                            }
                        }
                    }
                }            
            }        
        }               
    }
    
    .filter-controls{
        position:relative;
        @include selfClearing;
        z-index: 10;

        header{ 
            pointer-events: auto;
            cursor: pointer;
            background:$dblue;
            text-align: center;
            padding:4*$pad;
            margin-bottom:4*$mar;

            h2{ 
                color:$white;
                position: relative;
                display: inline-block;
                margin-bottom: 0;
                @include font-size(16px, 16px);               
                font-weight:$regular;

                &:after{
                    content: "\f107"; 
                    color: $w; 
                    margin-left:2*$mar;
                    @include fontAwesome;
                    @include vertical-align;                    
                    @include font-size(20px, 16px);
                }                
            } 
            
            &.active{   
                h2{ 
                    &:after {
                        content: "\f106";
                    }                 
                } 
            }
        }

        header.accordion-head{
            display:none;
        }

        main{ 
            position:relative;

            header.accordion-head{
                display:block;
            }

            .filter-grid{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom:$mar;
                display: none;

                nav{ 
                    width:auto;

                    ul{
                        padding: 0px;
                        margin:0px;
                        list-style: none;
                        overflow: hidden;
        
                        li{
                            @include font-size(14px, 16px);
                            padding: 2*$pad 3*$pad; 
                            margin: 2*$mar 2*$mar 2*$mar 0;                       
                            float: left;
                            text-align: center;
                            border:2px solid #b8b8be;                    
                            cursor: pointer;  
                            height:42px;
                            font-family: $aktivGrotesk;
                            align-items: center;
                            display: flex;
                            
                            img{
                                max-width: 14px;
                                vertical-align: bottom;
                                margin-right:$mar;
                            }
                            &.active{ 
                                border:2px solid $dblue; 
                                background:$lgrey;
                            }
                        }
                    }

                    .clearAll-cta{
                        border:0;                   
                        margin-left: 4*$mar;
                        padding-top: 3*$mar;
                        text-decoration:underline;    
                        a{
                            color:$dblue;
                        }            
                    }
                }
                .more-filters{
                    @include font-size(14px, 16px); 
                    right: 0;
                    position: relative;
                    text-decoration: underline;
                    color:$dblue;
                    cursor: pointer;
                    z-index:9;
                    font-family: $aktivGrotesk;
                                    
                    &::after{
                        @include fontAwesome;
                        content: "\f067";
                        color:$dblue;
                        margin-left:2*$mar;
                    }
                }
                &.acc{                        
                    &.acc-active{                        
                        .more-filters{                    
                            &::after{
                                content: "\f068";                           
                            }
                        }
                    }
                }
            }
        }

        .more-filter-option{                        
            border:2px solid $dblue;
            padding:3*$pad;
            margin:2*$mar 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .filter-calendar {
        float: none;
        margin-top: 0;
        margin-bottom:2*$mar;
        position: relative; 

        &.acc-active{ 
            .border-cta{ 
                background-color: $dblue;
                color: $white;	
            }	
        }
        .border-cta{ 
            @include padding-all(11px, 20px, 11px, 20px);	
            display:block;	
        }
        .acc-info{            
            left: 0;
            right: unset;	
        }
    }

    .filter-result-show{ 
        clear: both;
        .results{ 
            opacity: 0;
            height:0;
            visibility: hidden;
            transition: transform 0.5s ease, opacity 0.5s ease;

            &.active {
                height:100%;
                visibility: visible;
                opacity: 1;
            }
        }
        .border-cta{ 
            margin-top:10*$mar;
            padding:3*$pad 20*$pad;
        }         
    }    

    @media(min-width:992px){        
        h2.none{ 
            display:none;
        }        
        .filter-controls{ 
            header{ 
                pointer-events: none;
                cursor: default;
                background:transparent;
                text-align: left;
                padding:0;                
    
                h2{ 
                    color:$dblue;                    
                    display: block;                    
                    @include font-size(36px, 16px);               
                    font-weight:$bold;
    
                    &:after{
                        display: none;
                    }                
                }                
            }  
            
            header.accordion-head{
                display:block;
            }
    
            main{
                header.accordion-head{
                    display:none;
                }
                .filter-grid{
                    display:flex !important;                
                    position: relative;

                    nav{  
                        padding-right: 110px;                 
                        ul{
                            li{                        
                                margin: 2*$mar 3*$mar 2*$mar 0; 
                                padding: 2*$mar 4*$mar;                           
                            }
                        } 
                    }    
                    .more-filters{ 
                        right: 10px;  
                        top:20px;
                        position: absolute;                   
                    }
                    .more-filter-option{ 
                        nav{  
                            padding-right:0px;                          
                        }                   
                    }
                }
            }
        }              
        &.add-view-date{ 
            .filter-controls{
                main{ 
                    .filter-grid{                   
                        nav {
                            padding-right: 260px;
                        }
                        .more-filters {
                            right: 160px;                         
                        }
                        .more-filter-option{ 
                            nav{  
                                padding-right:0px;                          
                            }                   
                        }
                    }
                }
            }            
        }
    }     
}

.filter-nav {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;

    .select__wrapper {

        flex: 0 0 100%;
        display: block;
        margin-right: .5rem !important;
        margin-bottom: .5rem !important;
        width: 250px;
        padding: .75rem 1rem;
        border: solid 2px rgba($dblue, .3);
        @include transition(all, .2s);
        
        &.filter-calendar {            

            .select__wrapper__title {
                &::after {
                    content: "\f073";
                    font-size: 1rem;
                }
            }
        }

        .select__wrapper__title {

            position: relative;
            display: block;
            font-family: $aktivGrotesk;
            @include font-size(15px, 16px);
            font-weight: 500;
            cursor: pointer;

            &::after {
                content: "\f107";
                @extend .fas;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-weight: 400 !important;
                font-size: 1.25rem;
                @include transition(all, .2s)
            }
        }

        .select__wrapper__dropdown {

            overflow-y: hidden;
            max-height: 0;
            @include transition(all, .4s);
            
            > div{
                padding: 1rem 0;
            }

            label {
                display: flex;
                padding: .5rem 0;
                @include font-size(15px, 16px);
                font-family: $aktivGrotesk;
                font-weight: 500;

                input[type=checkbox] {

                    position: absolute !important;
                    height: 0 !important;
                    width: 0 !important;
                    opacity: 0 !important;
                    border: none !important;   
                    
                    &:checked + .filter__checkbox {
                        background: $dblue;
                    }
                }

                span.filter__checkbox {

                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 16px;
                    height: 16px;
                    border: solid 2px $dblue;
                    margin-right: .5rem;
                    cursor: pointer;
                    @include transition(all, .2s);

                    &::before {
                        content: "\f00c";
                        @extend .fas;
                        color: $white;
                        @include middle;
                        left: 55% !important;
                        font-weight: 400 !important;
                        font-size: .7rem;
                    }

                    &:checked {
                        background: $dblue;
                    }
                }

                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }

        &.open {

            border-color: $dblue;

            &:not(.filter-calendar) {
                .select__wrapper__title {
                    &::after {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }
            }            

            .select__wrapper__dropdown {
                max-height: 250px;
                overflow-y: auto;
            }
        }

        @include media(768px, min) {
            flex: 1 0 auto;
            margin-bottom: .5rem;
        }
    }

    .filter-clear {

        visibility: hidden;
        position: relative;
        flex: 0 0 auto;
        align-self: center;
        cursor: pointer;
        margin-top: 1rem;
        margin-bottom: .5rem;
        @include font-size(14px, 16px);
        font-family: $aktivGrotesk;
        font-weight: 500;

        &::after {
            content: "\f00d";
            @extend .fas;
            right: calc(-5px - 1rem);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 400 !important;
        }

        &.visible {
            visibility: visible;
        }
        
        @include media(768px, min) {
            
            margin-left: 2rem;
            margin-top: 0 !important;
        }
    }

}



