// GBL VARS
$gutter:30px;
//$pad:60px;

$bp-m:1080px;
$bp-s:720px;
$bp-xs:450px;

// HEADER HEIGHT
$hh-m:111px;
$hh-s:100px;

// LIBRARY
@import 'lib/_normalize';
@import 'lib/_mixins';
@import 'lib/_variables';

@import 'lib/_grid';
@import 'lib/_placeholders';


// == FONTS / ICONS
@import 'font-awesome/fontawesome';
@import 'font-awesome/brands';
@import 'font-awesome/duotone';
@import 'font-awesome/light';
@import 'font-awesome/regular';
@import 'font-awesome/solid';
@import 'font-awesome/v4-shims';

// == VENDOR COMPONENTS
@import 'vendor/_flickity';
@import 'vendor/_pignose.calendar.min';

// == GLOBAL ELEMENT 
@import 'gbl/_gblLayout';

@import "partials/animation";

// == LAYOUT SPECIFIC
@import 'partials/_header';
@import 'partials/_banner';
@import 'partials/_notice';
@import 'partials/_slider';
@import 'partials/_event.feature.slider';
@import 'partials/_news.story';
@import 'partials/_explore.map';
@import 'partials/_split.banner';
@import 'partials/inspiration';
@import 'partials/_our.picks';
@import 'partials/_filter.results';
@import 'partials/_tabs';
@import 'partials/_video';
@import 'partials/_forms';
@import 'partials/_accordion';
@import 'partials/_map';
@import 'partials/_add.banner';
@import 'partials/_two.column.txt';
@import 'partials/_thumb.img.blocks';
@import 'partials/_reviews';
@import 'partials/more.text';
@import 'partials/_news.filter.results';
@import 'partials/_like.dislike'; 
@import 'partials/_search';
@import 'partials/_calendar';
@import 'partials/_weekend.offer';
@import "partials/cycling-route.scss";
@import "partials/interactive-map.scss";
@import "partials/cookies";

// == COMPONETS
@import 'partials/components/_themes';
@import 'partials/components/_general';
@import 'partials/components/_social.media'; 
@import 'partials/components/_all.shape';
@import 'partials/components/_breadcrumb';
@import 'partials/components/_button';
@import 'partials/components/_layout';
@import 'partials/components/_card'; 
@import 'partials/components/_one.two.column.image';


// == FOOTER
@import 'partials/_footer';


// == TEMPLATE 
@import 'partials/layout/_itinerary.page';
@import 'partials/layout/_museum.page';
@import 'partials/layout/_contact.page';
@import 'partials/layout/_history.page';
@import 'partials/layout/_mailing.list.page';
@import 'partials/layout/_join.listing.page';
@import 'partials/layout/_payment.page';
@import 'partials/layout/_notice.post.page';
@import 'partials/layout/_notice.board.page';
@import 'partials/layout/_map.page';


// == DESKTOP & MOBILE MENU
@import 'partials/navbar/_navbar'; 
@import 'partials/navbar/_mobileMenu';















