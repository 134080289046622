.tabs {
    .tabInfo {
        &.key-info {
            h2 {
                font-size: 1.25rem;
                text-decoration: underline;
                font-weight: $med;
                margin-top: 1rem;
                margin-bottom: 0;

                &:first-of-type {
                    margin-top: 0.5rem;
                }
            }

            h2 + p {
                padding: 1.25rem 0;
                margin: 0;
            }
        }

        &.gallery-view {
            .cycle-route-gallery {
                padding-top: 55px;

                .flickity-prev-next-button.previous,
                .flickity-prev-next-button.next {
                    position: absolute;
                    top: 20px;
                    right: 0;
                    left: inherit;
                    height: 40px;
                    width: 40px;
                    cursor: pointer;
                    background: transparent;
                    border: solid 1px #000;
                    z-index: 1;
                    border-radius: 0;

                    svg {
                        display: none;
                    }

                    &.previous {
                        right: 55px;

                        &::after {
                            content: "\f104";
                        }
                    }

                    &.next {
                        &::after {
                            content: "\f105";
                        }
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                .flickity-prev-next-button.previous::after,
                .flickity-prev-next-button.next::after {
                    font-family: "Font Awesome 5 Pro";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    color: #000;
                    font-size: 20px;
                }

                .route-gallery-item {
                    width: calc(90% - 1rem);
                    padding: 0 0.5rem;

                    figure {
                        position: relative;
                        padding-top: 100%;

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }

                    @include media(648px, min) {
                        width: calc(50% - 1rem);
                        padding: 0 0.5rem;
                    }

                    @include media(768px, min) {
                        width: calc(33.33% - 1rem);
                    }

                    @include media(992px, min) {
                        width: calc(100% - 1rem);
                    }

                    @include media(1100px, min) {
                        width: calc(50% - 1rem);
                    }
                }
            }
        }
    }
}

.cycling-map-wrapper {
    position: relative;
    opacity: 0;   
    transform: translate3d(0,100px,0) ;

    #cycling-map {
        width: 100%;
        height: 350px;

        // .gm-style {
        //     > div {
        //         > div {
        //             div:nth-of-type(4) {
        //                 > div {
        //                     canvas {
        //                         box-shadow: 3px 3px 8px rgba(#141429, 0.5);
        //                         border-radius: 50%;
        //                     }

        //                     &:last-of-type {
        //                         canvas {
        //                             box-shadow: none;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }

    @include media(992px, min) {
        left: 50%;

        #cycling-map {
            height: 500px;
        }
    }

    #geolocation-error {
        display: none;
        p {
            font-family: $aktivGrotesk;
            font-weight: $med;
            font-size: 1.25rem;
        }
    }
}

.cycling-label {
    position: relative;
    top: -2px;
}

.museum-info.cycling-info {
    ul.details-info-list {
        li {
            
            @include media(992px, max) {
                display: inline-block;
                vertical-align: middle;
                margin-right: 2rem;
            }

            i.fa-bicycle {
                color: $green;
                margin-right: 0.25rem;
            }
        }
    }
}

.relevant-walks {
    .walks-slider {
        .flickity-prev-next-button.previous,
        .flickity-prev-next-button.next {
            visibility: hidden;
            position: absolute;
            top: -40px;
            right: 0;
            left: inherit;
            height: 40px;
            width: 40px;
            cursor: pointer;
            background: transparent;
            border: solid 1px #000;
            z-index: 1;
            border-radius: 0;

            svg {
                display: none;
            }

            &.previous {
                right: 55px;

                &::after {
                    content: "\f104";
                }
            }

            &.next {
                &::after {
                    content: "\f105";
                }
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            @include media(700px, min) {
                visibility: visible;
            }
        }

        .flickity-prev-next-button.previous::after,
        .flickity-prev-next-button.next::after {
            font-family: "Font Awesome 5 Pro";
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            color: #000;
            font-size: 20px;
        }

        .walks-slider-item {
            width: calc(90% - 1.5rem);
            padding: 0 0.75rem;

            h3 {
                padding: 1.25rem 0;
                margin: 0;
                font-weight: $med;
            }

            p {
                color: $dblue;
                margin: 0;
            }

            @include media(648px, min) {
                width: calc(50% - 1rem);
                padding: 0 0.5rem;
            }

            @include media(768px, min) {
                width: calc(33.33% - 1rem);
            }

            @include media(1024px, min) {
                width: calc(25% - 1rem);
            }
        }
    }
}

.location__popup {
    visibility: hidden;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(#141429, 0.4);
    z-index: 999;
    padding: 1rem;
    overflow-y: auto;

    .location__popup__inner {
        position: relative;
        background: #fff;
        padding: 40px;
        max-width: 450px;
        text-align: center;

        span#close-loc {
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
        }

        h3 {
            font-weight: $med;
            font-size: 1.75rem;
            margin: 0;
            padding: 1rem 0;
        }

        p {
            padding-bottom: 1.5rem;
            margin: 0;
            font-size: 1rem;
        }

        a, button {
            display: block;
            margin-bottom: 0.5rem;
            width: 100% !important;
            max-width: unset;
        }
    }

    #boundary-map {
        width: 100%;
        height: 300px;        
    }
}
