.more-text{
    overflow: hidden;
    display: none;          

    &.active{
        max-height: inherit;
        display: block;
        -webkit-line-clamp: inherit;
        -webkit-box-orient: inherit;
        text-overflow: inherit;
    }    
    &.active ~ .more-link {
        display: none;
    }
    &.active ~ .less-link {
        display: inline-block;
    }
    &.inactive ~ .less-link {
        display: none;
    } 

    @include media(768px){
        max-height: 143px;
    }    
}

.more-link{
    @include font-size(13px, 16px); 
    position: relative;    
    display: inline-block;
	color:$dblue;

    &:after{                
        @include fontAwesome;
        @include font-size(16, 16); 
        content: "\f107";
        display: inline-block;
        text-rendering: auto;
        padding-left: 4px;
        top: 2px;
        position: relative;
    }
}

.less-link{
    @extend .more-link;
	margin-top:3*$mar;    

    &:after{        
        content: "\f106";        
    }
}