.paymeny-page{    
    .inner{
        header{
            margin-bottom:8*$mar;

            h1{
                margin-bottom:0;
            }
        }        
    }    
}

.map-filter-block{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color:$dblue;
    color:$white;
    padding:0 3*$pad;
    @include font-size(18px, 16px);

    .map-filter-show{
        padding:4*$pad 0;
    }
    .map-filter{
        padding:4*$pad 0;
        img{
            max-width:20px;
            vertical-align: middle;
            margin-left:$mar;
        }
        a{
            color:$white;
        }
    }
}

.map-result{
    .map-filter-list{
        border-bottom:1px solid rgba($black, 0.2);
        border-top:1px solid rgba($black, 0.2);
        padding:$pad 0;
        margin:2*$mar 0;

        ul{
            padding: 0px;
            margin:0px;
            list-style: none;
            overflow: hidden;

            li{
                @include font-size(18px, 16px);
                padding: 0 0 0 5*$pad; 
                margin: 2*$mar 4*$mar 2*$mar 0;                       
                float: left;                               
                position:relative; 
                font-weight:$medium;               
                a{ 
                    display:block;
                    color:$dblue;

                    &:hover{
                        opacity: 0.8;
                    }
                }
                &:before{
                    content: "";                      
                    left:0;                    
                    @include vertical-align;
                    margin-right: $mar; 
                    width:14px;
                    height:14px;
                    //background: $dgrey; 
                }
                &.whatson-list{
                    color:$red;
                    &:before{
                        background-color:$red;
                    }            
                }
                &.explore-list{
                    &:before{
                        background-color:$yellow;
                    }             
                }
                &.experience-list{
                    &:before{
                        background-color:$blue;
                    }            
                }
                &.shop-list{
                    &:before{
                        background-color:$dblue;
                    }            
                }
                &.taste-list{
                    &:before{
                        background-color:$green;
                    }            
                }
                &.sleep-list{
                    &:before{
                        background-color:$purple;
                    }            
                }
                &.live-list{
                    &:before{
                        background-color:$lblue;
                    }            
                }
                &.more-list{
                    &:before{
                        background-color:$white;
                        border:3px solid $dblue;
                    }            
                }
            }
        }        
    }
    .map-result-show{
        position: relative;

        .list-grid{
            &-4{
                li{
                    @include flex-width(50%);

                    .cards{                    
                        .card{
                            figure {
                                height: 200px;                                
                            }
                            main {                                
                                min-height: auto; 
                                padding-bottom:0px;                                          
                            }                    
                        }
                    }
                }        
            }             
            
            @media (min-width:700px){           
                &-4{
                    li{
                        @include flex-width(25%);   
                        .cards{                    
                            .card{
                                figure {
                                    height: 320px;                                
                                }                                                   
                            }
                        }             
                    }        
                }          
            }
        }
    }
}

.mapViewLabel {    

    position: relative;
    top: 5px;
    margin-top: -32px/2;
    margin-left: -32px/2;
    transform: rotateX(55deg);

    &.active {
        &::after {
            content: "";
            display: block;
            width: 32px;
            height: 32px;
            animation: pulse 1.25s ease-out infinite;
            opacity: 0;
            border-radius: 50%;
            border: solid 2px $yellow;
        }
    }    
}

@keyframes pulse {
    
    0% {
        opacity: 0;
        transform: scale(.2, .2);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }

}







