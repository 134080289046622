.contact-us{
    margin-bottom:100px;
    
    .details-info-list{

        opacity: 0;
        border-bottom:1px solid rgba($black, 0.2);            
        margin-bottom:5*$mar; 
        padding-bottom:5*$pad;        
    }
    .social-link{ 
        border-bottom:1px solid rgba($black, 0.2);
        padding-bottom:3*$pad;
        margin-bottom:5*$mar;
    }
    h4{         
        padding-top:4*$pad;        
    }
}







