div#searchBar{
    background-color:$dblue;
    padding:10*$pad 0;           
    position:absolute;
    top:100%;
    left:0;            
    width:100%;
    box-shadow:0 10px 10px -5px $greyFade;
    display:none;

    .form-control{
        &:focus{
            outline:none; 
            border:0;
            @include border-radius(0px);
        }
    }

    .inner{
        display: block;
        justify-content: inherit;
        flex-wrap: inherit;
        align-items: flex-end;
        padding: 0 3*$pad;
    }

    .page-search{
        display:flex;
        align-items: center;
        position:relative;
        
        .fa-search{			
            color: $dblue; 
            @include fontAwesome;
            @include vertical-align;                    
            @include font-size(20px, 16px);
            left:15px;
            z-index: 1;
        } 
        .form-control{
            height: 56px;
            width: 100%; 
            padding-left:9*$pad;                       
        } 
        .cta{
            height: 56px;
            margin-left:3*$mar; 
            @include font-size(16px, 16px);
            padding:3*$pad 6*$pad;
            white-space: nowrap;
        } 
    }  

    .popularKeySearch{
        width: auto;
        display: flex;
        flex-wrap: wrap;
        margin:0 -2*$mar;

        p {
            display: block;
            padding-bottom: 1rem;
            color: #fff;
            font-family: $aktivGrotesk;
            flex: 0 0 100%;
            margin: 0 10px;
        }
         
        .form-control{
            flex: 1 100%;
            height: 56px;            
            border-bottom:1px solid rgba($white, 0.6);
            background:transparent;
            margin:0 2*$mar 4*$mar;
            color:$white;
            outline:none;

            &:focus{                
                border-bottom:1px solid rgba($white, 0.6);
            }
        }
        .border-cta {    
            flex: 2;
            margin: 0 2*$mar;        
            background:transparent;
            color:$white;
            border-color:$white;  
            &:hover{
                background-color:$blue;
                color:$w;
            }          
        }        
    } 

    @media (min-width:1020px){
        padding:6*$pad 0; 

        .inner {
            display:flex;
            justify-content:space-between;
            flex-wrap:nowrap;
            align-items: center;
            padding:0 10*$mar;
        }
        .page-search{
            .form-control{
                width: 330px; 
            } 
            .cta{
                margin-left:6*$mar;                 
            } 
        }
        .popularKeySearch{
            width: 360px;                   
        } 
    }
}

.search-page {
    #search-result-keyword {
        color: $blue;
    }

    .cards {
        @for $i from 1 through 3 {
            &:nth-of-type(#{$i}) {
                opacity: 0;
                @include animateFadeUp(300ms, 0, $i)
            }
        }
    }
}

    







