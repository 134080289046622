.cards {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;

    .card {
        position: relative;

        a {
            display: block;
            width: 100%;
            padding: 0;
        }

        figure {
            padding-top: 75%;
            width: 100%;
            overflow: hidden;
            position: relative;
            background: $white;

            @include media(648px, min) {
                padding-top: unset;
                height: 320px;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: none;
            }
        }
        main {
            //background: $white;
            padding: 1.5em 1.5em 1.5em 0;
            color: #000;
            min-height: 100px;
            max-height: 100%;

            .rating {
                font-weight: $med;
                font-family: $aktivGrotesk;
                display: block;
                margin-bottom: 3 * $pad;
                @include font-size(14px, 16px);

                .fas {
                    margin-right: $pad;
                    @include font-size(16px, 16px);
                }
                img {
                    position: relative;
                    top: -2px;
                    width: 18px;
                    margin-right: $mar;
                    vertical-align: middle;
                }
            }
            .price {
                display: block;
                margin-bottom: 3 * $pad;
                @include font-size(20px, 16px);
                font-weight: $light;
                font-family: $aktivGrotesk;

                strong {
                    font-weight: $bold;
                }
            }
            .date {
                font-weight: $med;
                font-family: $aktivGrotesk;

                display: block;
                margin-bottom: 2 * $pad;
                @include font-size(14px, 16px);

                img {
                    width: 18px;
                    margin-right: $mar;
                    vertical-align: middle;
                }
            }
            h3 {
                font-weight: $med;
                margin-bottom: 10px;
            }
            ul.group-icon {
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                
                > li {

                    position: relative;
                    display: flex;
                    padding: 0 5px;
                    -ms-flex: inherit;
                    flex: inherit;
                    max-width: inherit;

                    img {
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    &:hover span.tooltip {
                        
                        visibility: visible;
                        width: auto;
                        padding: .25rem .5rem;
                        overflow: unset;
                    }

                    span.tooltip {

                        position: absolute;
                        display: inline-block;
                        background: $dblue;
                        top: 25px;
                        left: 0;                       
                        visibility: hidden;
                        color: #fff;
                        font-family: $aktivGrotesk;
                        border-radius: 5px;
                        font-size: .875rem;
                        padding: 0;
                        z-index: 10;
                        text-align: center;
                        min-width: 0;
                        width: 0;
                        overflow: hidden;

                        &::before {

                            content: "";
                            position: absolute;
                            top: -5px;
                            left: 9px;
                            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                            background: $dblue;
                            width: 8px;
                            height: 5px;
                        }

                        &.light {
                            background: #fff !important;
                            color: $dblue !important;
                        }
                    }
                }
                > li > a {
                    display: flex;
                    width: 100%;
                    text-decoration: none;
                }
            }
            ul.details-info-list {
                margin-bottom: $mar;

                li {
                    display: inline-block;
                    vertical-align: middle;
                    width: auto;
                    margin-right: 4 * $mar;
                    font-family: $aktivGrotesk;
                    font-weight: $med;

                    [class*="fa-"] {
                        width: auto;
                        margin-right: 3px;
                        vertical-align: middle;
                    }
                    img {
                        width: 18px;
                        top: -2px;
                    }
                }
            }

            @include media(1024px, min) {
                padding-right: 2em;
            }
        }

        [class*="card-title"] {
            height: 70px;
            max-width: 150px;
            padding: 3 * $pad $pad 0 3 * $pad;
            background-color: $yellow;
            font-family: $aktivGrotesk;

            &:after {
                right: -60px;
                border-left: 50px solid transparent;
                border-right: 60px solid transparent;
                border-top: 70px solid $yellow;
            }
        }
        .news-card-title {
            background-color: $green;
            &:after {
                border-top: 70px solid $green;
            }
        }

        .card-title-yellow {
            &:after {
                border-top: 70px solid $yellow;
            }
        }
        .card-title-green {
            &:after {
                border-top: 70px solid $green;
            }
        }
        .card-title-blue {
            &:after {
                border-top: 70px solid $blue;
            }
        }
        .card-title-dblue {
            &:after {
                border-top: 70px solid $dblue;
            }
        }
        .card-title-red {
            &:after {
                border-top: 70px solid $red;
            }
        }
    }
}

.boards {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    height: 100%;

    .board {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        background-color: $white;
        height: 100%;

        header {
            position: relative;
            padding: 3 * $pad 0;
            color: $white;

            .inner {
                max-width: 415px;
                position: relative;
                margin: 0;
                padding-right: 12 * $pad;
            }

            &.bg-color-whatson {
                background-color: $red;
            }
            &.bg-color-explore {
                background-color: $yellow;
            }
            &.bg-color-experience {
                background-color: $blue;
            }
            &.bg-color-shop {
                background-color: $dblue;
            }
            &.bg-color-taste {
                background-color: $green;
            }
            &.bg-color-sleep {
                background-color: $purple;
            }
            &.bg-color-live {
                background-color: $lblue;
            }

            h6 {
                @include font-size(16px, 16px);
                color: $white;
                margin-bottom: $mar;
                span {
                    color: rgba($white, 0.6);
                    @include font-size(14px, 16px);
                    display: inline-block;
                    margin-left: 2 * $pad;
                    font-weight: $regular;
                }
            }
            .post-date {
                color: $white;
                @include font-size(13px, 16px);
                display: block;
                font-weight: $regular;
            }
            .share-this {
                @include vertical-align;
                border-color: $white;
                right: 15px;
                @include font-size(16px, 16px);
                padding: 6px 8px;

                .far {
                    color: $white;
                }
            }
        }
        main {
            position: relative;
            padding: 3 * $pad;

            h3 {
                a {
                    color: $dblue;
                }
            }
            p {
                margin: 0 0 1em;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
        footer {
            align-self: flex-end;
            position: relative;
            @include selfClearing;
            padding: 3 * $pad;
            display: flex;
            align-items: center;

            ul.breadcrumbs {
                max-width: 125px;
                margin-bottom: 0px;
                align-items: flex-start;

                li {
                    flex: 0 1 auto;
                    max-width: unset;
                    @include font-size(12px, 16px);
                    color: rgba($dblue, 0.6);
                    padding: 0 10px 0 0;
                    margin-right: 0;
                    margin-bottom: 2px;
                    &:last-child {
                        text-decoration: none;
                    }

                    &::after {
                        top: 0;
                        transform: none;
                        right: 3px;
                    }
                }
            }
            .like-dislike {
                margin-left: auto;
                margin-right: 3 * $mar;
                @include font-size(15px, 16px);
            }
            .border-cta {
                margin: 0px;
                padding: 2 * $pad 3 * $pad;
                border-color: $dblue;
                color: $dblue;
                &:hover {
                    background-color: $dblue;
                    color: $white;
                }
            }
        }
    }
}

.img-link-card {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;

    figure {
        padding-top: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;

        img.cover {

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            left: unset;
            transform: unset;
        }
    }

    [class*="-title"] {
        display: block;
        padding: 2 * $pad;
        color: $white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-family: $aktivGrotesk;
        font-weight: $med;
    }
    .whatson-title {
        background-color: $red;
    }
    .explore-title {
        background-color: $yellow;
    }
    .experience-title {
        background-color: $blue;
    }
    .shop-title {
        background-color: $dblue;
    }
    .taste-title {
        background-color: $green;
    }
    .sleep-title {
        background-color: $purple;
    }
    .live-title {
        background-color: $lblue;
    }

    [class*="title-"] {
        display: block;
        padding: 2 * $pad;
        color: $white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .title-yellow {
        background-color: $yellow;
    }
    .title-green {
        background-color: $green;
    }
    .title-blue {
        background-color: $blue;
    }
    .title-dblue {
        background-color: $dblue;
    }
    .title-red {
        background-color: $red;
    }
    .title-purple {
        background-color: $purple;
    }
    .title-lblue {
        background-color: $lblue;
    }
}


//Tooltip for dark backgrounds
section[class*="dblue"] {

    .tooltip {
        background: #fff !important;
        color: $dblue !important;

        &::before {
            background: #fff !important;
        }
    }
}

.slider {

    .group-icon {
        li {
            &:hover {
                span.tooltip {
                    position: absolute !important;
                }
            }
        }
    }

    .tooltip {

        position: relative !important;
        margin-top: 25px !important;
        top: 0 !important;        
    }
}