hr {
    border-top:2px solid rgba($black, 0.1);
}

.page-banner{
    position:relative;
    overflow: hidden;
    padding: 0;
    min-height:350px;

    figure {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
    } 

    p{
        margin:2*$mar 2*$mar 0; 
    }
    
    @media (min-width:992px){ 
        min-height:450px;
    }

    @include media(1024px, min) {
        min-height: 500px;
    }

    @include media(1366px, min) {
        min-height: 600px;
    }
}

blockquote{
    margin:8*$mar 0;
   display:block;   
   span{
       display: block;
       font-weight: $bold;
       @include font-size(14px, 16px);
   }
}

.details-info-list{    
    list-style:none;
    margin:0;
    padding:0;
    li{    

    margin-bottom:2*$mar;
    @include font-size(14px, 16px);
    font-weight: $med;
    font-family: $aktivGrotesk;

        a{           
            color:$dblue;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
        [class*=fa-]{
            width:25px;            
            @include font-size(18px, 16px);
        }
        img{
            position: relative;
            top: -2px;
            width:16px;
            margin-right:$pad;
            vertical-align: middle;            
        }
    }
}

.zoom-img{    
    a{
        display: block;
        width:100%;
    }            
    figure{        
        img{
            transition: transform .5s ease;
            width: 100%;
        }
    }
    &:hover{
        figure{
            img{
                transform: scale(1.2);
            } 
        }                       
    }   
}

.date-author{  
    @include font-size(14px, 16px);
    margin-bottom:3*$mar;     
    .date{
        display:inline-block;
        margin-right:4*$mar;
    }
    .author-name{
        display:inline-block;
    }
}










