
.footer {
    background-color: $dblue;
    color: $grey; 
    font-weight: $light;
    font-family: $aktivGrotesk;
    
    .inner{        
        position: relative;
        min-height:600px;
        @include padding-all(30px, 15px, 120px, 15px);

        &:before{
            content: '';                
            position: absolute;
            right:0px;
            bottom: 0px;
            width:240px;
            height:240px;
            background: url(../images/footer-shap-img.svg) left top no-repeat;
            background-size: 120%;
        }

        .footer-brand{
            padding-bottom:6*$pad;
            
            img {
                display: inline-block;
                width: 105px;

                @include media(1020px, min) {
                    width: 130px;
                }
            }
        }
        .footer-social-link{            
            position:absolute;
            right:15px;
            top:30px;
            z-index: 1;
            width:145px !important;
            text-align: right;
            @extend .social-link; 
    
            ul{
                li{
                    margin:0 0 2*$pad 2*$pad;                    

                    a{
                        background:$green;
                        width: 60px;
                        height:60px;
                    }
                }
            }
        }

        .footer-quick-link{
            ul{
                li{
                    padding-bottom:3*$pad;
                    a{
                        color:$white;
                        display: block;
                        font-weight:$bold;
                        @include font-size(20px, 16px);

                        &:hover{
                            color:$blue;                            
                        }
                    }
                }
            }
        }

        .footer-link{  
            @extend .footer-quick-link;          
            ul{
                li{                    
                    a{
                        font-weight:$regular;
                        @include font-size(18px, 16px);
                    }
                }
            }
        }

        
    }

    @media (min-width:992px){
        .inner{
            display: flex;
            flex-wrap: wrap; 
            @include padding-all(70px, 15px, 190px, 15px);

            &:before{                              
                width:480px;
                height:406px;
                background-size: 100%;                
            }
    
            .footer-brand{
                flex: 0 0 auto;
                width: 22%;
            }
            .footer-social-link{                                
                top:70px;
            }
            .footer-quick-link{
                flex: 0 0 auto;
                width: 22%;
    
                ul{
                    li{
                        padding-bottom:4*$pad;
                        a{
                            color:$white;
                            display: block;
                            font-weight:$bold;
                            @include font-size(30px, 16px);
    
                            &:hover{
                                color:$blue;                            
                            }
                        }
                    }
                }
            }
            .footer-link{            
                flex: 0 0 auto;
                width: 34%;
    
                ul{
                    li{
                        padding-bottom:5*$pad;
                        a{
                            font-weight:$regular;
                            @include font-size(20px, 16px);
                        }
                    }
                }
            }
        }
    } 
    
    
    
    ul{
        padding:0px;
        margin:0px;
        list-style: none;         
    }
}

.return--top {    

    visibility: hidden;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: $dblue;
    color: $white;    
    @include transition(all, .2s);
    cursor: pointer;
    z-index: -1;
    opacity: 0;

    &::before {
        content: "\f106";
        @extend .fas;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 1.5rem;
    }

    &:hover {
        opacity: 1;
    }

    @include media(1024px, min) {
        right: 40px;
        bottom: 40px;
    }

    &.visible {
        visibility: visible;
        z-index: 150;
        opacity: .9;
    }

}
    