.video-play {
	@include middle; 
	//@include border-radius(10px);        
	//width: 100px;
	//height: 60px;
	//background: rgba(0, 0, 0, 0.80); 
	color:$text-color;        
	border: none; 
	outline: none; 
	
	.fab{ 
		@include font-size(54px, 16px); 
	}
}

// Youtube Video Css
.video-bg {
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	@include middle;
	position: absolute !important; 

	.youtube-background {		
		border: 0;
	}
} 

.video-play-icon {
    @include middle;
    height: 80px;
    width: 80px;    
	//background: url(../images/video.play.png) no-repeat;
	background-size: 80px 80px;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform .1s ease;
	@include font-size(80px, 16px);

	.far{		
		top: -6px;
    	position: relative;
	}
}
