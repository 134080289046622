.explore-map{    
    background:$dblue;    
    overflow: hidden;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 50px;
    
    .short-info{        
        padding: 4*$pad $pad 6*$pad; 
        color:$white;

        h3{        
            @include font-size(30px, 16px);
            color:$white;
            font-weight:$bold;  
        }
        p{        
            @include font-size(14px, 16px);
            margin-bottom:6*$pad;
        }
    }
    .map-block{   
        clip-path: polygon(60% 0, 100% 100%, 100% 100%, 0 70%, 0 56%, 44% 0);
        margin: 0 -15px; 
    }
    
    .scroll-shape {
        background: url(../images/triangle-shap-img3.svg) left top no-repeat;
        top: 25px;
        left: 65px;
        height: 95px;
        width: 98px;
        bottom: unset;
        right: unset;
    }

    @media (min-width:992px){
        .row-col{
            .column-4{
                right: 66%;
                position: relative;
            }
            .column-8{
                left: 34%;
                position: relative;

                &.triangle{    
                    &:after{
                        top: 25px;
                        left: 65px;
                    }           
                } 
            } 
        } 
        .short-info{        
            padding: 14*$pad 0 14*$pad 14*$pad;
            h3{        
                @include font-size(36px, 16px);
            }
        }
        .map-block{   
            clip-path: polygon(70% 0, 100% 100%, 100% 100%, 47% 100%, 7% 63%, 39% 0);
            margin: 0px; 
        }
    } 
}











