.mailinglist-page{    
    .inner{
        header{
            margin-bottom:8*$mar;

            h1{
                margin-bottom:0;
            }
        }
    }    
}







