.museum-info-page{
    padding-top:0;

    .museum-info{
        padding:4*$pad 3*$pad;
    
        .details-info-list{            
            margin-bottom:8*$mar; 
        }        

        .share-site{ 
            border-bottom:1px solid rgba($black, 0.2);
            padding-bottom:5*$pad;
            margin-bottom:5*$mar;  
            
            ul{    
                list-style:none;
                margin:0;
                padding:0;
                display: flex;
                align-items: center;
        
                li{   
                    margin-right:15px; 
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    .share-this{ 
                        padding:2*$pad 3*$pad;      
                                           
                    }
                    .price{    
                        min-width:150px;
                        @include font-size(20px, 16px);
                    }

                    [class*='cta'] {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: auto;
                        height: 100%;
                        min-height: 46px;
                        font-size: 0.875rem !important;
                    }
                }
            }
        }

        .social-link{ 
            border-bottom:1px solid rgba($black, 0.2);
            padding-bottom:3*$pad;
            margin-bottom:5*$mar;
        }

        .tabs{ 
            main.tabsContainer{                       
                ul.tabs{
                    flex-wrap:wrap;
                    li.tab{
                        .tabInfo{
                            padding:3*$pad;
                            .details-info{    
                                margin-bottom:4*$mar;
                            }

                            .map-block {
                                position: sticky;
                                top: 0;
                                z-index: 10;
                            }
                        }
                    }
                }
            }
        }         
    }

    .museum-info {
        opacity: 0;
    }

    .offerSlider{        
        position: relative;         
        opacity: 0;   
        transform: translate3d(0,100px,0)        
    } 

    
}


@media (min-width:992px){
    .museum-info-page{
        padding-top:6*$pad;

        .row-col{
            .museum-info{
                padding:0 8*$pad;
                right: 50%;
                position: relative;      
                
                .tabs{ 
                    main.tabsContainer{                       
                        ul.tabs{                            
                            li.tab{
                                .tabInfo{
                                    padding:5*$pad;                                    
                                }
                            }
                        }
                    }
                } 
            }
            .offerSlider, .cycling-map-wrapper{
                left: 50%;
                position: relative;
                
                > div:first-of-type {
                    position: sticky !important;
                    top: 0;
                }
            } 
        } 
    }
}

.addthis_inline_share_toolbox_83op {
    display: inline-block;    
    position: relative;
    vertical-align: top;    
    border: solid 1px $dblue;
    background: $dblue !important;
    color: $white !important;
    width: 46px !important;
    height: 46px !important;
    @include transition(all, .2s);

    &:hover {
        
        
        &, .at-resp-share-element {
            background: $blue;
        }

        &::before {
            color: $white;
        }        
    }

    &::before {
        content: "\f1e0";
        @extend .fas;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        color: $white;
    }

    a.at-icon-wrapper {
        opacity: 0 !important;
    }

    > .at-resp-share-element {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .at-resp-share-element.at-share-btn {
        padding: 0;
        margin: 0;
    }

    a.at-icon-wrapper {
        padding: 0 !important;
        margin: 0 !important;
        @include transition(all, .2s);

        &:focus {
            transform: none !important;
        }
    }

    span.at-icon-wrapper {

        display: flex;
        align-items: center;
        width: unset !important;
        height: unset !important;
        margin-left: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            display: none;
        }

        &::before {
            content: "\f1e0";
            @extend .fas;
            color: #000;
            font-size: 1.5rem;
            font-weight: 400 !important;
        }
    }

    @include media(992px, min) {
        width: 54px !important;
        height: 54px !important;
    }
}




