.notice {    
    position: relative;    
    text-align: center;    
    color:$white;    
    line-height:20px;
    font-family: $aktivGrotesk;

    .inner{         
        padding:2*$pad 10*$pad;
        @include font-size(14px, 16px);

        p{
            @include font-size(14px, 16px);
            margin:0px;
        }

        a{
            color:$dblue;
            text-decoration: underline;
    
            &:hover{
                text-decoration: none; 
            }
        }
    } 
    &.notice-whatson{background-color:$red;}
    &.notice-explore{background-color:$yellow;}
    &.notice-experience{background-color:$blue;}
    &.notice-shop{background-color:$dblue;}
    &.notice-taste{background-color:$green;}
    &.notice-sleep{background-color:$purple;}
    &.notice-live{
        background-color:$dblue;
        a{
            color:$white;
        }
    }

    @media (min-width:992px){
        .inner{         
            padding:4*$pad 0;        
            @include font-size(18px, 16px);

            p{
                @include font-size(18px, 16px);
            }
        }     
    }
}



    







